import produce from 'immer';
import {
  PLANS_LIST_REQUEST,
  PLANS_REQUEST_SUCCESS,
  PLANS_REQUEST_ERROR,
  PLAN_CREATION_REQUEST,
  PLAN_EDIT_REQUEST,
  PLAN_DELETE_REQUEST,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case PLANS_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case PLANS_REQUEST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      case PLANS_REQUEST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      case PLAN_CREATION_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case PLAN_EDIT_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case PLAN_DELETE_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      default:
        return state;
    }
  });
};

import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/paymentAuthorization${objectToURLParams(parameters)}`);

export const post = data => api.post('/admin/paymentAuthorization', data);

export const update = (id, data) =>
  api.put(`/admin/paymentAuthorization/${id}`, data);

export const remove = id => api.delete(`/admin/paymentAuthorization/${id}`);

export const recover = id =>
  api.put(`/admin/paymentAuthorization/${id}/undelete`);

export const ORGANIZATION_INVOICES_LIST_REQUEST =
  'ORGANIZATION_INVOICES_LIST_REQUEST';
export const ORGANIZATION_INVOICES_LIST_SUCCESS =
  'ORGANIZATION_INVOICES_LIST_SUCCESS';
export const ORGANIZATION_INVOICES_LIST_ERROR =
  'ORGANIZATION_INVOICES_LIST_ERROR';

export const ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST =
  'ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST';
export const ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS =
  'ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS';
export const ORGANIZATION_INVOICES_TRANSACTIONS_ERROR =
  'ORGANIZATION_INVOICES_TRANSACTIONS_ERROR';

export const ORGANIZATION_INVOICES_UPDATE_STATUS_REQUEST =
  'ORGANIZATION_INVOICES_UPDATE_STATUS_REQUEST';
export const ORGANIZATION_INVOICES_UPDATE_STATUS_SUCCESS =
  'ORGANIZATION_INVOICES_UPDATE_STATUS_SUCCESS';
export const ORGANIZATION_INVOICES_UPDATE_STATUS_ERROR =
  'ORGANIZATION_INVOICES_UPDATE_STATUS_ERROR';

export const ORGANIZATION_INVOICES_REPROCESS_REQUEST =
  'ORGANIZATION_INVOICES_REPROCESS_REQUEST';
export const ORGANIZATION_INVOICES_REPROCESS_SUCCESS =
  'ORGANIZATION_INVOICES_REPROCESS_SUCCESS';
export const ORGANIZATION_INVOICES_REPROCESS_ERROR =
  'ORGANIZATION_INVOICES_REPROCESS_ERROR';

export const listOrganizationInvoicesRequest = params => ({
  type: ORGANIZATION_INVOICES_LIST_REQUEST,
  payload: { params },
});

export const listOrganizationInvoicesSuccess = payload => ({
  type: ORGANIZATION_INVOICES_LIST_SUCCESS,
  payload,
});

export const listOrganizationInvoicesError = message => ({
  type: ORGANIZATION_INVOICES_LIST_ERROR,
  payload: { message },
});

export const organizationInvoicesTransactions = params => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
  payload: { params },
});

export const organizationInvoicesTransactionsSuccess = payload => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_SUCCESS,
  payload,
});

export const organizationInvoicesTransactionsError = message => ({
  type: ORGANIZATION_INVOICES_TRANSACTIONS_ERROR,
  payload: { message },
});

export const organizationInvoicesUpdateStatusRequest = (id, data, params) => ({
  type: ORGANIZATION_INVOICES_UPDATE_STATUS_REQUEST,
  payload: { id, data, params },
});

export const organizationInvoicesUpdateStatusSuccess = payload => ({
  type: ORGANIZATION_INVOICES_UPDATE_STATUS_SUCCESS,
  payload,
});

export const organizationInvoicesUpdateStatusError = message => ({
  type: ORGANIZATION_INVOICES_UPDATE_STATUS_ERROR,
  payload: { message },
});

export const reprocessInvoices = (data, params) => ({
  type: ORGANIZATION_INVOICES_REPROCESS_REQUEST,
  payload: { data, params },
});

export const reprocessInvoicesSuccess = () => ({
  type: ORGANIZATION_INVOICES_REPROCESS_SUCCESS,
});

export const reprocessInvoicesError = message => ({
  type: ORGANIZATION_INVOICES_REPROCESS_ERROR,
  payload: { message },
});

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  listOrganizationInvoicesSuccess,
  listOrganizationInvoicesError,
  organizationInvoicesTransactionsSuccess,
  organizationInvoicesTransactionsError,
  organizationInvoicesUpdateStatusSuccess,
  organizationInvoicesUpdateStatusError,
  listOrganizationInvoicesRequest,
  reprocessInvoicesSuccess,
  reprocessInvoicesError,
  ORGANIZATION_INVOICES_LIST_REQUEST,
  ORGANIZATION_INVOICES_REPROCESS_REQUEST,
  ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
  ORGANIZATION_INVOICES_UPDATE_STATUS_REQUEST,
} from './actions';

function* listOrganizationInvoices(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listOrganizationInvoicesSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listOrganizationInvoicesError(message));
  }
}

function* getOrganizationInvoicesTransactions(api, { payload }) {
  const response = yield call(api.getTransactions, APIParams(payload.params));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(organizationInvoicesTransactionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(organizationInvoicesTransactionsError(message));
  }
}

function* updateStatus(api, { payload }) {
  const response = yield call(api.updateStatus, payload.id, payload.data);

  if (response.ok) {
    yield put(organizationInvoicesUpdateStatusSuccess(response.data));
    yield put(listOrganizationInvoicesRequest(payload.params));
  } else {
    const message = 'Não foi possível atualizar o pagamento';

    yield put(organizationInvoicesUpdateStatusError(message));
  }
}

function* reprocessOrganizationInvoices(api, { payload }) {
  const response = yield call(api.reprocessBillings, payload.data);

  if (response.ok) {
    yield put(reprocessInvoicesSuccess(response));
    yield put(listOrganizationInvoicesRequest(payload.params));
  } else {
    const message = 'Não foi possível reprocessar os pagamentos';

    yield put(reprocessInvoicesError(message));
  }
}

export function* watchListOrganizationInvoices(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_LIST_REQUEST,
    listOrganizationInvoices,
    api
  );
}

export function* watchOrganizationInvoicesTransactions(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_TRANSACTIONS_REQUEST,
    getOrganizationInvoicesTransactions,
    api
  );
}

export function* watchUpdateStatus(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_UPDATE_STATUS_REQUEST,
    updateStatus,
    api
  );
}

export function* watchReprocessOrganizationInvoices(api) {
  yield takeEvery(
    ORGANIZATION_INVOICES_REPROCESS_REQUEST,
    reprocessOrganizationInvoices,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListOrganizationInvoices, api),
    fork(watchOrganizationInvoicesTransactions, api),
    fork(watchUpdateStatus, api),
    fork(watchReprocessOrganizationInvoices, api),
  ]);
}

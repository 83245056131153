import produce from 'immer';

import {
  DEPOSIT_LIST_REQUEST,
  DEPOSIT_LIST_SUCCESS,
  DEPOSIT_LIST_ERROR,
  DEPOSIT_ATTACHMENTS_REQUEST,
  DEPOSIT_ATTACHMENT_SUCCESS,
  DEPOSIT_ATTACHMENT_ERROR,
  CREATE_DEPOSIT_ATTACHMENT,
  DELETE_DEPOSIT_ATTACHMENT,
  CANCEL_DEPOSIT_REQUEST,
  CANCEL_DEPOSIT_SUCCESS,
  CANCEL_DEPOSIT_ERROR,
  CANCEL_DEPOSIT_RESET,
} from '../actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  attachments: [],
  cancelDeposit: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case DEPOSIT_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case DEPOSIT_LIST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;

      case DEPOSIT_LIST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;

      case DEPOSIT_ATTACHMENTS_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      case DEPOSIT_ATTACHMENT_SUCCESS:
        draft.loading = false;
        draft.attachments = action.payload.list;
        draft.error = '';
        break;

      case DEPOSIT_ATTACHMENT_ERROR:
        draft.loading = false;
        draft.attachments = [];
        draft.error = action.payload.message;
        break;

      case CREATE_DEPOSIT_ATTACHMENT:
        draft.loading = true;
        draft.error = '';
        break;

      case DELETE_DEPOSIT_ATTACHMENT:
        draft.loading = true;
        draft.error = '';
        break;

      case CANCEL_DEPOSIT_REQUEST:
        draft.cancelDeposit.loading = true;
        draft.cancelDeposit.error = '';
        draft.cancelDeposit.success = false;
        break;

      case CANCEL_DEPOSIT_SUCCESS:
        draft.cancelDeposit.loading = false;
        draft.cancelDeposit.error = '';
        draft.cancelDeposit.success = true;
        break;

      case CANCEL_DEPOSIT_ERROR:
        draft.cancelDeposit.loading = false;
        draft.cancelDeposit.error = action.payload.message;
        draft.cancelDeposit.success = false;
        break;

      case CANCEL_DEPOSIT_RESET:
        draft.cancelDeposit.loading = false;
        draft.cancelDeposit.error = '';
        draft.cancelDeposit.success = false;
        break;

      default:
        return state;
    }
  });
};

export const DEPOSIT_LIST_REQUEST = 'DEPOSIT_LIST_REQUEST';
export const DEPOSIT_LIST_SUCCESS = 'DEPOSIT_LIST_SUCCESS';
export const DEPOSIT_LIST_ERROR = 'DEPOSIT_LIST_ERROR';
export const DEPOSIT_ATTACHMENTS_REQUEST = 'DEPOSIT_ATTACHMENTS_REQUEST';
export const DEPOSIT_ATTACHMENT_SUCCESS = 'DEPOSIT_ATTACHMENT_SUCCESS';
export const DEPOSIT_ATTACHMENT_ERROR = 'DEPOSIT_ATTACHMENT_ERROR';
export const CREATE_DEPOSIT_ATTACHMENT = 'CREATE_DEPOSIT_ATTACHMENT';
export const DELETE_DEPOSIT_ATTACHMENT = 'DELETE_DEPOSIT_ATTACHMENT';
export const CANCEL_DEPOSIT_REQUEST = 'CANCEL_DEPOSIT_REQUEST';
export const CANCEL_DEPOSIT_SUCCESS = 'CANCEL_DEPOSIT_SUCCESS';
export const CANCEL_DEPOSIT_ERROR = 'CANCEL_DEPOSIT_ERROR';
export const CANCEL_DEPOSIT_RESET = 'CANCEL_DEPOSIT_RESET';

export const listDeposits = params => ({
  type: DEPOSIT_LIST_REQUEST,
  payload: { params },
});

export const onListDepositsSuccess = payload => ({
  type: DEPOSIT_LIST_SUCCESS,
  payload,
});

export const onListDepositsError = message => ({
  type: DEPOSIT_LIST_ERROR,
  payload: { message },
});

export const listDepositAttachments = params => ({
  type: DEPOSIT_ATTACHMENTS_REQUEST,
  payload: { params },
});

export const onDepositAttachmentSuccess = payload => ({
  type: DEPOSIT_ATTACHMENT_SUCCESS,
  payload,
});

export const onDepositAttachmentError = message => ({
  type: DEPOSIT_ATTACHMENT_ERROR,
  payload: { message },
});

export const createDepositAttachment = params => ({
  type: CREATE_DEPOSIT_ATTACHMENT,
  payload: { params },
});

export const deleteDepositAttachment = (id, deposit) => ({
  type: DELETE_DEPOSIT_ATTACHMENT,
  payload: { id, deposit },
});

export const cancelDeposit = (id, data) => ({
  type: CANCEL_DEPOSIT_REQUEST,
  payload: { id, data },
});

export const onCancelDepositSuccess = payload => ({
  type: CANCEL_DEPOSIT_SUCCESS,
  payload,
});

export const onCancelDepositError = message => ({
  type: CANCEL_DEPOSIT_ERROR,
  payload: { message },
});

export const onCancelDepositReset = () => ({
  type: CANCEL_DEPOSIT_RESET,
});

import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/rootUsers${objectToURLParams(parameters)}`);

export const post = body => api.post(`/user`, { ...body });

export const edit = (id, user) => api.put(`/user/${id}`, user);

export const remove = id => api.delete(`/user/${id}`);

export const undelete = id => api.put(`/user/recover/${id}`);

import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(
    `/admin/organization/gas-station-billing${objectToURLParams(parameters)}`
  );

export const getTransactions = parameters =>
  api.get(`/admin/transaction${objectToURLParams(parameters)}`);

export const reprocessBillings = payload =>
  api.post('/admin/organization/gas-station-billing', payload);

export const updateStatus = (id, data) =>
  api.put(`/admin/organization/gas-station-billing/${id}`, data);

export const scheduleBilling = id =>
  api.post(`/admin/organization/gas-station-billing/schedule/${id}`);

export const getPaymentHistory = parameters =>
  api.get(
    `/admin/organization/gas-station-billing/payment${objectToURLParams(
      parameters
    )}`
  );

export const ACTIONS = {
  GET_PERMISSIONS: 'ADMIN_GET_PERMISSIONS',
  PERMISSION_ON_SUCCESS: 'PERMISSION_ON_SUCCESS',
  PERMISSION_ON_ERROR: 'PERMISSION_ON_ERROR',
  CREATE_PERMISSION: 'ADMIN_POST_PERMISSION',
  EDIT_PERMISSION: 'ADMIN_PUT_PERMISSION',
  DELETE_PERMISSION: 'ADMIN_DELETE_PERMISSION',
};

export const getPermissions = params => ({
  type: ACTIONS.GET_PERMISSIONS,
  payload: { params },
});

export const onSuccess = permissions => ({
  type: ACTIONS.PERMISSION_ON_SUCCESS,
  payload: { permissions },
});

export const onError = message => ({
  type: ACTIONS.PERMISSION_ON_ERROR,
  payload: { message },
});

export const createPermission = permissions => ({
  type: ACTIONS.CREATE_PERMISSION,
  payload: { permissions },
});

export const editPermission = (id, permission) => ({
  type: ACTIONS.EDIT_PERMISSION,
  payload: { id, permission },
});

export const deletePermission = permission => ({
  type: ACTIONS.DELETE_PERMISSION,
  payload: { permission },
});

import produce from 'immer';

import { ADMIN_ORG_USER_ACTIONS } from './actions';

const INIT_STATE = {
  users: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  success: false,
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_SUCCESS: {
        draft.loading = false;
        draft.users = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_ERROR: {
        draft.loading = false;
        draft.users = [];
        draft.error = action.payload.message;
        break;
      }

      case ADMIN_ORG_USER_ACTIONS.ADMIN_CREATE_ORG_USER: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ADMIN_ORG_USER_ACTIONS.ADMIN_EDIT_ORG_USER: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ADMIN_ORG_USER_ACTIONS.ADMIN_DELETE_ORG_USER: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      default:
        return state;
    }
  });
};

export const ADMIN_STATION_USER_ACTIONS = {
  ADMIN_GET_STATION_USERS_REQUEST: 'ADMIN_GET_STATION_USERS_REQUEST',
  ADMIN_GET_STATION_USERS_SUCCESS: 'ADMIN_GET_STATION_USERS_SUCCESS',
  ADMIN_GET_STATION_USERS_ERROR: 'ADMIN_GET_STATION_USERS_ERROR',
  ADMIN_CREATE_STATION_USER: 'ADMIN_CREATE_STATION_USER',
  ADMIN_EDIT_STATION_USER: 'ADMIN_EDIT_STATION_USER',
  ADMIN_DELETE_STATION_USER: 'ADMIN_DELETE_STATION_USER',
  ADMIN_UNDELETE_STATION_USER: 'ADMIN_UNDELETE_STATION_USER',
  ADMIN_UNDELETE_USER_STATION_ERROR: 'ADMIN_UNDELETE_USER_STATION_ERROR',
};

export const getStationUsersRequest = params => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_GET_STATION_USERS_REQUEST,
  payload: { params },
});

export const getStationUsersSuccess = payload => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_GET_STATION_USERS_SUCCESS,
  payload,
});

export const getStationUsersError = message => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_GET_STATION_USERS_ERROR,
  payload: { message },
});

export const createStationUserRequest = (user, params) => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_CREATE_STATION_USER,
  payload: { user, params },
});

export const editStationUserRequest = (id, user, params) => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_EDIT_STATION_USER,
  payload: { id, user, params },
});

export const deleteStationUserRequest = (user, params) => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_DELETE_STATION_USER,
  payload: { user, params },
});

export const undeleteStationUserRequest = (id, params) => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_UNDELETE_STATION_USER,
  payload: { id, params },
});

export const undeleteStationUserError = message => ({
  type: ADMIN_STATION_USER_ACTIONS.ADMIN_UNDELETE_USER_STATION_ERROR,
  payload: { message },
});

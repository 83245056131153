import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as paymentAuthorization from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const paymentAuthorizationList = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      paymentAuthorization.onListPaymentAuthorizationSuccess({
        list: paymentAuthorizationList,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(paymentAuthorization.onListPaymentAuthorizationError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.data);

  if (response.ok) {
    yield put(paymentAuthorization.onCreatePaymentAuthorizationSuccess());
  } else {
    const message = 'Não foi possível criar autorização';
    yield put(paymentAuthorization.onCreatePaymentAuthorizationError(message));
  }
}

function* update(api, { payload }) {
  const response = yield call(api.update, payload.id, payload.data);

  if (response.ok) {
    yield put(paymentAuthorization.onUpdatePaymentAuthorizationSuccess(payload.params));
  } else {
    const message = 'Não foi possível editar autorização';
    yield put(paymentAuthorization.onUpdatePaymentAuthorizationError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.id);

  if (response.ok) {
    yield put(paymentAuthorization.onRemovePaymentAuthorizationSuccess(payload.params));
  } else {
    const message = 'Não foi possível remover autorização';
    yield put(paymentAuthorization.onRemovePaymentAuthorizationError(message));
  }
}

function* recover(api, { payload }) {
  const response = yield call(api.recover, payload.id);

  if (response.ok) {
    yield put(
      paymentAuthorization.onRecoverPaymentAuthorizationSuccess(payload.params)
    );
  } else {
    const message = 'Não foi possível recuperar autorização';
    yield put(paymentAuthorization.onRecoverPaymentAuthorizationError(message));
  }
}

function* watchList(api) {
  yield takeEvery(paymentAuthorization.LIST_PAYMENT_AUTHORIZATION, list, api);
}

function* watchPost(api) {
  yield takeEvery(paymentAuthorization.CREATE_PAYMENT_AUTHORIZATION, post, api);
}

function* watchUpdate(api) {
  yield takeEvery(paymentAuthorization.UPDATE_PAYMENT_AUTHORIZATION, update, api);
}

function* watchRemove(api) {
  yield takeEvery(paymentAuthorization.REMOVE_PAYMENT_AUTHORIZATION, remove, api);
}

function* watchRecover(api) {
  yield takeEvery(paymentAuthorization.RECOVER_PAYMENT_AUTHORIZATION, recover, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchUpdate, api),
    fork(watchRemove, api),
    fork(watchRecover, api),
  ]);
}

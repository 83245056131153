import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  FRAUD_SUSPICION_LIST_REQUEST,
  FRAUD_SUSPICION_CHANGE_STATUS_REQUEST,
  listFraudSuspicionsSuccess,
  listFraudSuspicionsError,
  listFraudSuspicions as listFraudSuspicionsAction,
  changeStatusFraudSuspicionSuccess,
  changeStatusFraudSuspicionError,
} from './actions';

function* listFraudSuspicions(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFraudSuspicionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFraudSuspicionsError(message));
  }
}

function* changeStatusFraudSuspicion(api, { payload }) {
  const { id, data, params } = payload;
  const response = yield call(api.changeStatus, id, data);

  if (response.ok) {
    yield put(changeStatusFraudSuspicionSuccess(response.data));
    yield put(listFraudSuspicionsAction(params));
  } else {
    const message =
      'Não foi possível alterar o status desta tentativa de fraude';

    yield put(changeStatusFraudSuspicionError(message));
  }
}

export function* watchListFraudSuspicions(api) {
  yield takeEvery(FRAUD_SUSPICION_LIST_REQUEST, listFraudSuspicions, api);
}

export function* watchChangeStatusFraudSuspicion(api) {
  yield takeEvery(
    FRAUD_SUSPICION_CHANGE_STATUS_REQUEST,
    changeStatusFraudSuspicion,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListFraudSuspicions, api),
    fork(watchChangeStatusFraudSuspicion, api),
  ]);
}

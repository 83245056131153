export const MODULE_LIST_REQUEST = 'MODULE_LIST_REQUEST';
export const MODULE_LIST_SUCCESS = 'MODULE_LIST_SUCCESS';
export const MODULE_LIST_ERROR = 'MODULE_LIST_ERROR';
export const CREATE_MODULE = 'CREATE_MODULE';
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_ERROR = 'CREATE_MODULE_ERROR';
export const EDIT_MODULE = 'EDIT_MODULE';
export const EDIT_MODULE_SUCCESS = 'EDIT_MODULE_SUCCESS';
export const EDIT_MODULE_ERROR = 'EDIT_MODULE_ERROR';
export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_ERROR = 'DELETE_MODULE_ERROR';
export const UNDELETE_MODULE = 'UNDELETE_MODULE';
export const UNDELETE_MODULE_ERROR = 'UNDELETE_MODULE_ERROR';

export const getModule = params => ({
  type: MODULE_LIST_REQUEST,
  payload: { params },
});

export const listModuleSuccess = payload => ({
  type: MODULE_LIST_SUCCESS,
  payload,
});

export const listModuleError = message => ({
  type: MODULE_LIST_ERROR,
  payload: { message },
});

export const createModule = (module, params) => ({
  type: CREATE_MODULE,
  payload: { module, params },
});

export const createModuleSuccess = payload => ({
  type: CREATE_MODULE_SUCCESS,
  payload,
});

export const createModuleError = message => ({
  type: CREATE_MODULE_ERROR,
  payload: { message },
});

export const editModule = (id, module, params) => ({
  type: EDIT_MODULE,
  payload: { id, module, params },
});

export const editModuleSuccess = payload => ({
  type: EDIT_MODULE_SUCCESS,
  payload,
});

export const editModuleError = message => ({
  type: EDIT_MODULE_ERROR,
  payload: { message },
});

export const deleteModule = (module, params) => ({
  type: DELETE_MODULE,
  payload: { module, params },
});

export const deleteModuleSuccess = payload => ({
  type: DELETE_MODULE_SUCCESS,
  payload,
});

export const deleteModuleError = message => ({
  type: DELETE_MODULE_ERROR,
  payload: { message },
});

export const undeleteModuleRequest = (id, params) => ({
  type: UNDELETE_MODULE,
  payload: { id, params },
});

export const undeleteModuleError = message => ({
  type: UNDELETE_MODULE_ERROR,
  payload: { message },
});

import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  permissions: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_PERMISSIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ACTIONS.PERMISSION_ON_SUCCESS: {
        draft.loading = false;
        draft.permissions = action.payload.permissions;
        draft.error = '';
        break;
      }

      case ACTIONS.PERMISSION_ON_ERROR: {
        draft.loading = false;
        draft.permissions = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.CREATE_PERMISSION: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.EDIT_PERMISSION: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.DELETE_PERMISSION: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      default:
        return state;
    }
  });

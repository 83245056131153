import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as stationUsers from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const users = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      stationUsers.getStationUsersSuccess({
        users,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(stationUsers.getStationUsersError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.user);

  if (response.ok) {
    yield put(stationUsers.getStationUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível criar o usuário';
    yield put(stationUsers.getStationUsersError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.edit, payload.id, payload.user);

  if (response.ok) {
    yield put(stationUsers.getStationUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o usuário';
    yield put(stationUsers.getStationUsersError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.user);

  if (response.ok) {
    yield put(stationUsers.getStationUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o usuário';
    yield put(stationUsers.getStationUsersError(message));
  }
}

function* undelete(api, { payload }) {
  const response = yield call(api.undelete, payload.id);

  if (response.ok) {
    yield put(stationUsers.getStationUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o usuário';
    yield put(stationUsers.getStationUsersError(message));
  }
}

function* watchList(api) {
  yield takeEvery(
    stationUsers.ADMIN_STATION_USER_ACTIONS.ADMIN_GET_STATION_USERS_REQUEST,
    list,
    api
  );
}

function* watchPost(api) {
  yield takeEvery(
    stationUsers.ADMIN_STATION_USER_ACTIONS.ADMIN_CREATE_STATION_USER,
    post,
    api
  );
}

function* watchPut(api) {
  yield takeEvery(
    stationUsers.ADMIN_STATION_USER_ACTIONS.ADMIN_EDIT_STATION_USER,
    edit,
    api
  );
}

function* watchDelete(api) {
  yield takeEvery(
    stationUsers.ADMIN_STATION_USER_ACTIONS.ADMIN_DELETE_STATION_USER,
    remove,
    api
  );
}

function* watchUndelete(api) {
  yield takeEvery(
    stationUsers.ADMIN_STATION_USER_ACTIONS.ADMIN_UNDELETE_STATION_USER,
    undelete,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchUndelete, api),
  ]);
}

export const FUEL_MANAGEMENT_LIST_REQUEST = 'FUEL_MANAGEMENT_LIST_REQUEST';
export const FUEL_MANAGEMENT_LIST_SUCCESS = 'FUEL_MANAGEMENT_LIST_SUCCESS';
export const FUEL_MANAGEMENT_LIST_ERROR = 'FUEL_MANAGEMENT_LIST_ERROR';
export const FUEL_MANAGEMENT_CREATE_REQUEST = 'FUEL_MANAGEMENT_CREATE_REQUEST';
export const FUEL_MANAGEMENT_CREATE_SUCCESS = 'FUEL_MANAGEMENT_CREATE_SUCCESS';
export const FUEL_MANAGEMENT_CREATE_ERROR = 'FUEL_MANAGEMENT_CREATE_ERROR';
export const FUEL_MANAGEMENT_RESET = 'FUEL_MANAGEMENT_RESET';
export const FUEL_MANAGEMENT_UPDATE_REQUEST = 'FUEL_MANAGEMENT_UPDATE_REQUEST';
export const FUEL_MANAGEMENT_UPDATE_SUCCESS = 'FUEL_MANAGEMENT_UPDATE_SUCCESS';
export const FUEL_MANAGEMENT_UPDATE_ERROR = 'FUEL_MANAGEMENT_UPDATE_ERROR';
export const FUEL_MANAGEMENT_DELETE_REQUEST = 'FUEL_MANAGEMENT_DELETE_REQUEST';
export const FUEL_MANAGEMENT_DELETE_SUCCESS = 'FUEL_MANAGEMENT_DELETE_SUCCESS';
export const FUEL_MANAGEMENT_DELETE_ERROR = 'FUEL_MANAGEMENT_DELETE_ERROR';
export const FUEL_MANAGEMENT_RECOVER_REQUEST =
  'FUEL_MANAGEMENT_RECOVER_REQUEST';
export const FUEL_MANAGEMENT_RECOVER_SUCCESS =
  'FUEL_MANAGEMENT_RECOVER_SUCCESS';
export const FUEL_MANAGEMENT_RECOVER_ERROR = 'FUEL_MANAGEMENT_RECOVER_ERROR';
export const GET_FUEL_MANAGEMENT_BY_ID = 'GET_FUEL_MANAGEMENT_BY_ID';
export const GET_FUEL_MANAGEMENT_BY_ID_SUCCESS =
  'GET_FUEL_MANAGEMENT_BY_ID_SUCCESS';
export const GET_FUEL_MANAGEMENT_BY_ID_ERROR =
  'GET_FUEL_MANAGEMENT_BY_ID_ERROR';

export const listFuelManagement = params => ({
  type: FUEL_MANAGEMENT_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_LIST_SUCCESS,
  payload,
});

export const listFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_LIST_ERROR,
  payload: { message },
});

export const createFuelManagement = (data, params) => ({
  type: FUEL_MANAGEMENT_CREATE_REQUEST,
  payload: { data, params },
});

export const createFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_CREATE_SUCCESS,
  payload,
});

export const createFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_CREATE_ERROR,
  payload: { message },
});

export const resetFuelManagement = () => ({
  type: FUEL_MANAGEMENT_RESET,
});

export const updateFuelManagement = (id, data, params) => ({
  type: FUEL_MANAGEMENT_UPDATE_REQUEST,
  payload: { id, data, params },
});

export const updateFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_UPDATE_SUCCESS,
  payload,
});

export const updateFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_UPDATE_ERROR,
  payload: { message },
});

export const deleteFuelManagement = (id, params) => ({
  type: FUEL_MANAGEMENT_DELETE_REQUEST,
  payload: { id, params },
});

export const deleteFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_DELETE_SUCCESS,
  payload,
});

export const deleteFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_DELETE_ERROR,
  payload: { message },
});

export const recoverFuelManagement = (id, params) => ({
  type: FUEL_MANAGEMENT_RECOVER_REQUEST,
  payload: { id, params },
});

export const recoverFuelManagementSuccess = payload => ({
  type: FUEL_MANAGEMENT_RECOVER_SUCCESS,
  payload,
});

export const recoverFuelManagementError = message => ({
  type: FUEL_MANAGEMENT_RECOVER_ERROR,
  payload: { message },
});

export const getFuelManagementById = id => ({
  type: GET_FUEL_MANAGEMENT_BY_ID,
  payload: { id },
});

export const getFuelManagementByIdSuccess = tank => ({
  type: GET_FUEL_MANAGEMENT_BY_ID_SUCCESS,
  payload: { tank },
});

export const getFuelManagementByIdError = message => ({
  type: GET_FUEL_MANAGEMENT_BY_ID_ERROR,
  payload: { message },
});

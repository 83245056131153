import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/transaction${objectToURLParams(parameters)}`);

export const cancel = (id, body) =>
  api.post(`/admin/transaction/${id}/cancel`, body);

export const addNotes = (id, body) =>
  api.post(`/admin/transaction/${id}/notes`, body);

export const createTransaction = body => api.post(`/admin/refuel`, body);

// import fetchJsonp from 'fetch-jsonp';
import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/permission${objectToURLParams(parameters)}`);

export const post = payload => api.post('/permission', payload);

export const put = (id, payload) => api.put(`/permission/${id}`, payload);

export const remove = id => api.delete(`/permission/${id}`);

import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/discountRate${objectToURLParams(parameters)}`);

export const getById = id => api.get(`/discountRate/${id}`);

export const post = payload => api.post('/discountRate', payload);

export const put = (id, payload) => api.put(`/discountRate/${id}`, payload);

export const remove = id => api.delete(`/discountRate/${id}`);

export const getTransactionDiscount = (stationId, orgId) =>
  api.get(`/admin/gasStation/${stationId}/discountRate/${orgId}`);

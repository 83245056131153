import produce from 'immer';

import {
  FRAUD_SUSPICION_LIST_REQUEST,
  FRAUD_SUSPICION_LIST_SUCCESS,
  FRAUD_SUSPICION_LIST_ERROR,
  FRAUD_SUSPICION_CHANGE_STATUS_REQUEST,
  FRAUD_SUSPICION_CHANGE_STATUS_SUCCESS,
  FRAUD_SUSPICION_CHANGE_STATUS_ERROR,
  FRAUD_SUSPICION_RESET_CHANGE_STATUS_STATE,
} from './actions';

const INITIAL_STATE = {
  list: [],
  updateStatus: {
    loading: false,
    error: '',
    success: false,
  },
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FRAUD_SUSPICION_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case FRAUD_SUSPICION_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case FRAUD_SUSPICION_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case FRAUD_SUSPICION_CHANGE_STATUS_REQUEST: {
        draft.updateStatus.loading = true;
        draft.updateStatus.error = '';
        draft.updateStatus.success = false;
        break;
      }

      case FRAUD_SUSPICION_CHANGE_STATUS_SUCCESS: {
        draft.updateStatus.loading = false;
        draft.updateStatus.success = true;
        draft.updateStatus.error = '';
        break;
      }

      case FRAUD_SUSPICION_CHANGE_STATUS_ERROR: {
        draft.updateStatus.loading = false;
        draft.updateStatus.success = false;
        draft.updateStatus.error = action.payload.message;
        break;
      }

      case FRAUD_SUSPICION_RESET_CHANGE_STATUS_STATE: {
        draft.updateStatus.loading = false;
        draft.updateStatus.success = false;
        draft.updateStatus.error = '';
        break;
      }

      default:
        return state;
    }
  });
};

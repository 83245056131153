import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/plan${objectToURLParams(parameters)}`);

export const create = data => api.post('/admin/plan', data);

export const edit = (id, data) => api.put(`/admin/plan/${id}`, data);

export const remove = id => api.delete(`/plan/${id}`);

import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  vehicles: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_VEHICLE: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case ACTIONS.VEHICLE_ON_SUCCESS: {
        draft.loading = false;
        draft.vehicles = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case ACTIONS.VEHICLE_ON_ERROR: {
        draft.loading = false;
        draft.vehicles = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.CREATE_VEHICLE: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.EDIT_VEHICLE: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.DELETE_VEHICLE: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      default:
        return state;
    }
  });

import produce from 'immer';

import {
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_ERROR,
  TRANSACTION_CANCEL_REQUEST,
  TRANSACTION_CANCEL_SUCCESS,
  TRANSACTION_CANCEL_ERROR,
  TRANSACTION_CANCEL_RESET_STATE,
  TRANSACTION_ADD_NOTES_REQUEST,
  TRANSACTION_ADD_NOTES_SUCCESS,
  TRANSACTION_ADD_NOTES_ERROR,
  TRANSACTION_ADD_NOTES_RESET_STATE,
  CREATE_TRANSACTION_ERROR,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_REQUEST,
  CREATE_TRASACTION_RESET_STATE,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  cancelTransaction: {
    loading: false,
    error: '',
    success: false,
  },
  addNotesTransaction: {
    loading: false,
    error: '',
    success: false,
  },
  createTransaction: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case TRANSACTION_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case TRANSACTION_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case TRANSACTION_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case TRANSACTION_CANCEL_REQUEST: {
        draft.cancelTransaction.loading = true;
        draft.cancelTransaction.error = '';
        break;
      }
      case TRANSACTION_CANCEL_SUCCESS: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.success = true;
        draft.cancelTransaction.error = '';
        break;
      }
      case TRANSACTION_CANCEL_ERROR: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.success = false;
        draft.cancelTransaction.error = action.payload.message;
        break;
      }
      case TRANSACTION_CANCEL_RESET_STATE: {
        draft.cancelTransaction.loading = false;
        draft.cancelTransaction.success = false;
        draft.cancelTransaction.error = '';
        break;
      }

      case TRANSACTION_ADD_NOTES_REQUEST: {
        draft.addNotesTransaction.loading = true;
        draft.addNotesTransaction.error = '';
        break;
      }

      case TRANSACTION_ADD_NOTES_SUCCESS: {
        draft.addNotesTransaction.loading = false;
        draft.addNotesTransaction.success = true;
        draft.addNotesTransaction.error = '';
        break;
      }

      case TRANSACTION_ADD_NOTES_ERROR: {
        draft.addNotesTransaction.loading = false;
        draft.addNotesTransaction.success = false;
        draft.addNotesTransaction.error = action.payload.message;
        break;
      }

      case TRANSACTION_ADD_NOTES_RESET_STATE: {
        draft.addNotesTransaction.loading = false;
        draft.addNotesTransaction.success = false;
        draft.addNotesTransaction.error = '';
        break;
      }

      case CREATE_TRANSACTION_REQUEST: {
        draft.createTransaction.success = false;
        draft.createTransaction.loading = true;
        draft.createTransaction.error = '';
        break;
      }

      case CREATE_TRANSACTION_SUCCESS: {
        draft.createTransaction.loading = false;
        draft.createTransaction.success = true;
        draft.createTransaction.error = '';
        break;
      }

      case CREATE_TRANSACTION_ERROR: {
        draft.createTransaction.loading = false;
        draft.createTransaction.success = false;
        draft.createTransaction.error = action.payload.message;
        break;
      }

      case CREATE_TRASACTION_RESET_STATE: {
        draft.createTransaction.loading = false;
        draft.createTransaction.success = false;
        draft.createTransaction.error = '';
        break;
      }

      default:
        return state;
    }
  });
};

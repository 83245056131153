export const GAS_STATION_LIST_ACTIONS = {
  GET_GAS_STATION_LIST: 'GET_GAS_STATION_LIST',
  GAS_STATION_LIST_ON_SUCCESS: 'GAS_STATION_LIST_ON_SUCCESS',
  GAS_STATION_LIST_ON_ERROR: 'GAS_STATION_LIST_ON_ERROR',
};

export const getGasStationList = params => ({
  type: GAS_STATION_LIST_ACTIONS.GET_GAS_STATION_LIST,
  payload: { params },
});

export const getGasStationListSuccess = payload => ({
  type: GAS_STATION_LIST_ACTIONS.GAS_STATION_LIST_ON_SUCCESS,
  payload,
});

export const getGasStationListError = message => ({
  type: GAS_STATION_LIST_ACTIONS.GAS_STATION_LIST_ON_ERROR,
  payload: { message },
});

import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as gasStations from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const gasStationList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };
    yield put(
      gasStations.getGasStationsSuccess({ list: gasStationList, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* listNoReceivables(api, { payload }) {
  const response = yield call(
    api.listWithoutReceivables,
    APIParams({ ...payload.params })
  );

  if (response.ok) {
    const gasStationList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      gasStations.getGasStationsSuccess({ list: gasStationList, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* listWithoutBlackList(api, { payload }) {
  const response = yield call(
    api.listWithoutBlackList,
    payload.orgId,
    APIParams({ ...payload.params })
  );

  if (response.ok) {
    const gasStationList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      gasStations.getGasStationsSuccess({ list: gasStationList, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.gasStation);

  if (response.ok) {
    yield put(gasStations.getGasStations(payload.params));
  } else {
    const message = 'Não foi possível criar o posto de combustível';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.gasStation);

  if (response.ok) {
    yield put(gasStations.getGasStations(payload.params));
  } else {
    const message = 'Não foi possível editar o posto de combustível';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.gasStation);

  if (response.ok) {
    yield put(gasStations.getGasStations(payload.params));
  } else {
    const message = 'Não foi possível remover o posto de combustível';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* undelete(api, { payload }) {
  const response = yield call(api.undelete, payload.id);

  if (response.ok) {
    yield put(gasStations.getGasStations(payload.params));
  } else {
    const message = 'Não foi possível recuperar o posto de combustível';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* makePayment(api, { payload }) {
  const response = yield call(api.makePayment, payload.data);

  if (response.ok) {
    yield put(gasStations.getGasStations(payload.params));
  } else {
    const message = 'Não foi possível adicionar pagamento';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* getById(api, { payload }) {
  const response = yield call(api.getById, payload.gasStation);

  if (response.ok) {
    yield put(gasStations.getGasStationByIdSuccess(response.data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(gasStations.getGasStationsError(message));
  }
}

function* watchList(api) {
  yield takeEvery(gasStations.GAS_STATION_ACTIONS.GET_GAS_STATION, list, api);
}

function* watchPost(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.CREATE_GAS_STATION,
    post,
    api
  );
}

function* watchPut(api) {
  yield takeEvery(gasStations.GAS_STATION_ACTIONS.EDIT_GAS_STATION, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.DELETE_GAS_STATION,
    remove,
    api
  );
}

function* watchUndelete(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.UNDELETE_GAS_STATION,
    undelete,
    api
  );
}

function* watchMakePayment(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.MAKE_GAS_STATION_PAYMENT,
    makePayment,
    api
  );
}

function* watchListNoReceivables(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.GET_GAS_STATION_NO_RECEIVABLES,
    listNoReceivables,
    api
  );
}

function* watchListWithoutBlackList(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.GET_GAS_STATION_BLACK_LIST,
    listWithoutBlackList,
    api
  );
}

function* watchGetById(api) {
  yield takeEvery(
    gasStations.GAS_STATION_ACTIONS.GET_GAS_STATION_BY_ID,
    getById,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchUndelete, api),
    fork(watchMakePayment, api),
    fork(watchListNoReceivables, api),
    fork(watchGetById, api),
    fork(watchListWithoutBlackList, api),
  ]);
}

import { all, put, call, takeEvery, fork } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  MODULE_LIST_REQUEST,
  DELETE_MODULE,
  CREATE_MODULE,
  EDIT_MODULE,
  listModuleSuccess,
  listModuleError,
  getModule,
  createModuleSuccess,
  createModuleError,
  editModuleSuccess,
  editModuleError,
  deleteModuleSuccess,
  deleteModuleError,
} from './actions';

function* listModules(api, { payload }) {
  const modules = yield call(
    api.list,
    APIParams({
      ...payload.params,
    })
  );

  if (modules.ok) {
    const list = modules.data.docs;
    const { current: currentPage, total: totalPages } = modules.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = modules.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listModuleSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listModuleError(message));
  }
}

function* createModule(api, { payload }) {
  const { module: data, params } = payload;
  const response = yield call(api.create, data);

  if (response.ok) {
    yield put(createModuleSuccess(response.data));
    yield put(getModule(params));
  } else {
    const message = 'Não foi possível criar este módulo';
    yield put(createModuleError(message));
  }
}

function* editModule(api, { payload }) {
  const { id, module: data, params } = payload;
  const response = yield call(api.edit, id, data);

  if (response.ok) {
    yield put(editModuleSuccess(response.data));
    yield put(getModule(params));
  } else {
    const message = 'Não foi possível editar este módulo';
    yield put(editModuleError(message));
  }
}

function* deleteModule(api, { payload }) {
  const { module: data, params } = payload;
  const response = yield call(api.remove, data._id);

  if (response.ok) {
    yield put(deleteModuleSuccess(response.data));
    yield put(getModule(params));
  } else {
    const message = 'Não foi possível deletar este módulo';
    yield put(deleteModuleError(message));
  }
}

export function* watchListModules(api) {
  yield takeEvery(MODULE_LIST_REQUEST, listModules, api);
}

export function* watchCreateModule(api) {
  yield takeEvery(CREATE_MODULE, createModule, api);
}

export function* watchEditModule(api) {
  yield takeEvery(EDIT_MODULE, editModule, api);
}

export function* watchDeleteModule(api) {
  yield takeEvery(DELETE_MODULE, deleteModule, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListModules, api),
    fork(watchCreateModule, api),
    fork(watchEditModule, api),
    fork(watchDeleteModule, api),
  ]);
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as bills from './actions';

function* listBills(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const { aggregateTransactions } = response.data;

    yield put(bills.onListSuccess(aggregateTransactions));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(bills.onListError(message));
  }
}

function* listBillsPerPeriod(api, { payload }) {
  const response = yield call(
    api.listPerPeriod,
    APIParams({ ...payload.params })
  );

  if (response.ok) {
    if (response.data.aggregateTransactionsByPeriod.length) {
      const { aggregateTransactionsByPeriod } = response.data;

      yield put(bills.onListPerPeriodSuccess(aggregateTransactionsByPeriod));
    } else {
      yield put(bills.onListPerPeriodSuccess([]));
    }
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(bills.onListPerPeriodError(message));
  }
}

export function* watchListBills(api) {
  yield takeEvery(bills.ACTIONS.BILL_LIST_REQUEST, listBills, api);
}

export function* watchListBillsPerPeriod(api) {
  yield takeEvery(bills.ACTIONS.BILLS_PER_PERIOD, listBillsPerPeriod, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListBills, api), fork(watchListBillsPerPeriod, api)]);
}

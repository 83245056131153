import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  BILLING_STATEMENTS_ACTIONS,
  listBillingStatementsSuccess,
  listBillingStatementsError,
} from './actions';

function* listBillingStatement(api, { payload }) {
  const billingStatements = yield call(api.list, APIParams({ ...payload }));

  if (billingStatements.ok) {
    const list = billingStatements.data.docs;

    const {
      current: currentPage,
      total: totalPages,
    } = billingStatements.data.pages;

    const {
      total: totalItems,
      skip: skipItems = 0,
    } = billingStatements.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listBillingStatementsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listBillingStatementsError(message));
  }
}

export function* watchListBillingStatements(api) {
  yield takeEvery(
    BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_REQUEST,
    listBillingStatement,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchListBillingStatements, api)]);
}

import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as adminUsers from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const adminUsersList = response.data.docs;
    yield put(adminUsers.getAdminUsersSuccess(adminUsersList));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(adminUsers.getAdminUsersError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.user);

  if (response.ok) {
    yield put(adminUsers.getAdminUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível criar o usuário';
    yield put(adminUsers.getAdminUsersError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.edit, payload.id, payload.user);

  if (response.ok) {
    yield put(adminUsers.getAdminUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o usuário';
    yield put(adminUsers.getAdminUsersError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.user);

  if (response.ok) {
    yield put(adminUsers.getAdminUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o usuário';
    yield put(adminUsers.getAdminUsersError(message));
  }
}

function* undelete(api, { payload }) {
  const response = yield call(api.undelete, payload.id);

  if (response.ok) {
    yield put(adminUsers.getAdminUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o usuário';
    yield put(adminUsers.getAdminUsersError(message));
  }
}

function* watchList(api) {
  yield takeEvery(
    adminUsers.ADMIN_USER_ACTIONS.ADMIN_GET_USERS_REQUEST,
    list,
    api
  );
}

function* watchPost(api) {
  yield takeEvery(adminUsers.ADMIN_USER_ACTIONS.ADMIN_CREATE_USER, post, api);
}

function* watchPut(api) {
  yield takeEvery(adminUsers.ADMIN_USER_ACTIONS.ADMIN_EDIT_USER, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(adminUsers.ADMIN_USER_ACTIONS.ADMIN_DELETE_USER, remove, api);
}

function* watchUndelete(api) {
  yield takeEvery(
    adminUsers.ADMIN_USER_ACTIONS.ADMIN_UNDELETE_USER,
    undelete,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchUndelete, api),
  ]);
}

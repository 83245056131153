import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  listMoneyTransferSuccess,
  listMoneyTransferError,
  MONEY_TRANSFER_LIST_REQUEST,
} from './actions';

function* listMoneyTransfer(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listMoneyTransferSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listMoneyTransferError(message));
  }
}

export function* watchListMoneyTransfer(api) {
  yield takeEvery(MONEY_TRANSFER_LIST_REQUEST, listMoneyTransfer, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListMoneyTransfer, api)]);
}

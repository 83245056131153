import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  TRANSACTION_LIST_REQUEST,
  TRANSACTION_CANCEL_REQUEST,
  listTransactionSuccess,
  listTransactionError,
  cancelTransactionSuccess,
  cancelTransactionError,
  TRANSACTION_ADD_NOTES_REQUEST,
  addNotesTransactionSuccess,
  addNotesTransactionError,
  createTransactionSuccess,
  createTransactionError,
  CREATE_TRANSACTION_REQUEST,
  listTransaction,
} from './actions';

function* listTransactions(api, { payload }) {
  const transactions = yield call(api.list, APIParams({ ...payload.params }));

  if (transactions.ok) {
    const list = transactions.data.docs;
    const { current: currentPage, total: totalPages } = transactions.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = transactions.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listTransactionSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listTransactionError(message));
  }
}

function* cancelTransactions(api, { payload }) {
  const response = yield call(api.cancel, payload.id, payload.body);

  if (response.ok) {
    yield put(cancelTransactionSuccess(response.data));
  } else {
    const message = 'Não foi possível cancelar a transação';
    yield put(cancelTransactionError(message));
  }
}

function* addNotesTransactions(api, { payload }) {
  const response = yield call(api.addNotes, payload.id, payload.body);

  if (response.ok) {
    yield put(addNotesTransactionSuccess(response.data));
  } else {
    const message = 'Não foi possível adicionar as notas';
    yield put(addNotesTransactionError(message));
  }
}

function* createTransaction(api, { payload }) {
  const response = yield call(api.createTransaction, payload.body);

  if (response.ok) {
    yield put(createTransactionSuccess(response.data));
    yield put(listTransaction(payload.params));
  } else {
    const message = 'Não foi possível criar transação';
    yield put(createTransactionError(message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(TRANSACTION_LIST_REQUEST, listTransactions, api);
}

export function* watchCancelTransaction(api) {
  yield takeEvery(TRANSACTION_CANCEL_REQUEST, cancelTransactions, api);
}

export function* watchAddNotesTransaction(api) {
  yield takeEvery(TRANSACTION_ADD_NOTES_REQUEST, addNotesTransactions, api);
}

export function* watchCreateTransaction(api) {
  yield takeEvery(CREATE_TRANSACTION_REQUEST, createTransaction, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListTransactions, api),
    fork(watchCancelTransaction, api),
    fork(watchAddNotesTransaction, api),
    fork(watchCreateTransaction, api),
  ]);
}

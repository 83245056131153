export const ADMIN_ORG_USER_ACTIONS = {
  ADMIN_GET_ORG_USERS_REQUEST: 'ADMIN_GET_ORG_USERS_REQUEST',
  ADMIN_GET_ORG_USERS_SUCCESS: 'ADMIN_GET_ORG_USERS_SUCCESS',
  ADMIN_GET_ORG_USERS_ERROR: 'ADMIN_GET_ORG_USERS_ERROR',
  ADMIN_CREATE_ORG_USER: 'ADMIN_CREATE_ORG_USER',
  ADMIN_EDIT_ORG_USER: 'ADMIN_EDIT_ORG_USER',
  ADMIN_DELETE_ORG_USER: 'ADMIN_DELETE_ORG_USER',
  ADMIN_UNDELETE_ORG_USER: 'ADMIN_UNDELETE_ORG_USER',
  ADMIN_UNDELETE_ORG_USER_ERROR: 'ADMIN_UNDELETE_ORG_USER_ERROR',
};

export const getOrgUsersRequest = params => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_REQUEST,
  payload: { params },
});

export const getOrgUsersSuccess = payload => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_SUCCESS,
  payload,
});

export const getOrgUsersError = message => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_ERROR,
  payload: { message },
});

export const createOrgUserRequest = (user, params) => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_CREATE_ORG_USER,
  payload: { user, params },
});

export const editOrgUserRequest = (id, user, params) => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_EDIT_ORG_USER,
  payload: { id, user, params },
});

export const deleteOrgUserRequest = (user, params) => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_DELETE_ORG_USER,
  payload: { user, params },
});

export const undeleteOrgUserRequest = (id, organizationId, params) => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_UNDELETE_ORG_USER,
  payload: { id, organizationId, params },
});

export const undeleteOrgUserError = message => ({
  type: ADMIN_ORG_USER_ACTIONS.ADMIN_UNDELETE_ORG_USER_ERROR,
  payload: { message },
});

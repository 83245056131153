import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as orgUsers from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const orgUsersList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      orgUsers.getOrgUsersSuccess({ list: orgUsersList, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(orgUsers.getOrgUsersError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.user);

  if (response.ok) {
    yield put(orgUsers.getOrgUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível criar o usuário';
    yield put(orgUsers.getOrgUsersError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.edit, payload.id, payload.user);

  if (response.ok) {
    yield put(orgUsers.getOrgUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível editar o usuário';
    yield put(orgUsers.getOrgUsersError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.user);

  if (response.ok) {
    yield put(orgUsers.getOrgUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível remover o usuário';
    yield put(orgUsers.getOrgUsersError(message));
  }
}

function* undelete(api, { payload }) {
  const response = yield call(api.undelete, payload.id, payload.organizationId);

  if (response.ok) {
    yield put(orgUsers.getOrgUsersRequest(payload.params));
  } else {
    const message = 'Não foi possível recuperar o usuário';
    yield put(orgUsers.getOrgUsersError(message));
  }
}

function* watchList(api) {
  yield takeEvery(
    orgUsers.ADMIN_ORG_USER_ACTIONS.ADMIN_GET_ORG_USERS_REQUEST,
    list,
    api
  );
}

function* watchPost(api) {
  yield takeEvery(
    orgUsers.ADMIN_ORG_USER_ACTIONS.ADMIN_CREATE_ORG_USER,
    post,
    api
  );
}

function* watchPut(api) {
  yield takeEvery(
    orgUsers.ADMIN_ORG_USER_ACTIONS.ADMIN_EDIT_ORG_USER,
    edit,
    api
  );
}

function* watchDelete(api) {
  yield takeEvery(
    orgUsers.ADMIN_ORG_USER_ACTIONS.ADMIN_DELETE_ORG_USER,
    remove,
    api
  );
}

function* watchUndelete(api) {
  yield takeEvery(
    orgUsers.ADMIN_ORG_USER_ACTIONS.ADMIN_UNDELETE_ORG_USER,
    undelete,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchUndelete, api),
  ]);
}

import produce from 'immer';

import {
  GAS_STATION_BILLINGS_LIST_REQUEST,
  GAS_STATION_BILLINGS_LIST_SUCCESS,
  GAS_STATION_BILLINGS_LIST_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST,
  GAS_STATION_BILLINGS_UPDATE_STATUS_SUCCESS,
  GAS_STATION_BILLINGS_UPDATE_STATUS_ERROR,
  GAS_STATION_BILLINGS_REPROCESS_REQUEST,
  GAS_STATION_BILLINGS_REPROCESS_SUCCESS,
  GAS_STATION_BILLINGS_REPROCESS_ERROR,
  SCHEDULE_GAS_STATION_BILLINGS_REQUEST,
  SCHEDULE_GAS_STATION_BILLINGS_SUCCESS,
  SCHEDULE_GAS_STATION_BILLINGS_ERROR,
  SCHEDULE_GAS_STATION_BILLINGS_RESET,
  GET_PAYMENT_HISTORY_REQUEST,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  schedule: {
    loading: false,
    error: '',
    success: false,
  },
  transactions: {
    list: [],
    currentPage: '',
    totalPages: '',
    totalItems: '',
    skipItems: 0,
    error: '',
    loading: false,
    success: false,
  },
  makePayment: {
    loading: false,
    error: '',
    success: false,
  },
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  paymentHistory: {
    list: [],
    currentPage: '',
    totalPages: '',
    totalItems: '',
    skipItems: 0,
    error: '',
    loading: false,
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GAS_STATION_BILLINGS_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case GET_TRANSACTIONS_REQUEST: {
        draft.transactions.loading = true;
        draft.transactions.error = '';
        break;
      }
      case GET_TRANSACTIONS_SUCCESS: {
        draft.transactions.loading = false;
        draft.transactions.list = action.payload.list;
        draft.transactions.currentPage = action.payload.pages.currentPage;
        draft.transactions.totalPages = action.payload.pages.totalPages;
        draft.transactions.totalItems = action.payload.items.totalItems;
        draft.transactions.skipItems = action.payload.items.skipItems;
        draft.transactions.error = '';
        break;
      }
      case GET_TRANSACTIONS_ERROR: {
        draft.transactions.loading = false;
        draft.transactions.list = [];
        draft.transactions.error = action.payload.message;
        break;
      }
      case GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST: {
        draft.makePayment.loading = true;
        draft.makePayment.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_UPDATE_STATUS_SUCCESS: {
        draft.makePayment.loading = false;
        draft.makePayment.success = true;
        draft.makePayment.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_UPDATE_STATUS_ERROR: {
        draft.makePayment.loading = false;
        draft.makePayment.success = false;
        draft.makePayment.error = action.payload.message;
        break;
      }
      case GAS_STATION_BILLINGS_REPROCESS_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_REPROCESS_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case GAS_STATION_BILLINGS_REPROCESS_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      case SCHEDULE_GAS_STATION_BILLINGS_REQUEST: {
        draft.schedule.loading = true;
        draft.schedule.error = '';
        break;
      }
      case SCHEDULE_GAS_STATION_BILLINGS_SUCCESS: {
        draft.schedule.loading = false;
        draft.schedule.success = true;
        draft.schedule.error = '';
        break;
      }
      case SCHEDULE_GAS_STATION_BILLINGS_ERROR: {
        draft.schedule.loading = false;
        draft.schedule.success = false;
        draft.schedule.error = action.payload.message;
        break;
      }
      case SCHEDULE_GAS_STATION_BILLINGS_RESET: {
        draft.schedule.loading = false;
        draft.schedule.success = false;
        draft.schedule.error = '';
        break;
      }
      case GET_PAYMENT_HISTORY_REQUEST: {
        draft.paymentHistory.loading = true;
        draft.paymentHistory.error = '';
        break;
      }
      case GET_PAYMENT_HISTORY_SUCCESS: {
        draft.paymentHistory.loading = false;
        draft.paymentHistory.list = action.payload.list;
        draft.paymentHistory.currentPage = action.payload.pages.currentPage;
        draft.paymentHistory.totalPages = action.payload.pages.totalPages;
        draft.paymentHistory.totalItems = action.payload.items.totalItems;
        draft.paymentHistory.skipItems = action.payload.items.skipItems;
        draft.paymentHistory.error = '';
        break;
      }
      case GET_PAYMENT_HISTORY_ERROR: {
        draft.paymentHistory.loading = false;
        draft.paymentHistory.list = [];
        draft.paymentHistory.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  listFuelManagement as listFuelManagementAction,
  listFuelManagementSuccess,
  listFuelManagementError,
  createFuelManagementSuccess,
  createFuelManagementError,
  FUEL_MANAGEMENT_LIST_REQUEST,
  FUEL_MANAGEMENT_CREATE_REQUEST,
  FUEL_MANAGEMENT_UPDATE_REQUEST,
  updateFuelManagementError,
  updateFuelManagementSuccess,
  FUEL_MANAGEMENT_DELETE_REQUEST,
  deleteFuelManagementSuccess,
  deleteFuelManagementError,
  FUEL_MANAGEMENT_RECOVER_REQUEST,
  recoverFuelManagementError,
  recoverFuelManagementSuccess,
  getFuelManagementByIdSuccess,
  getFuelManagementByIdError,
  GET_FUEL_MANAGEMENT_BY_ID,
} from './actions';

function* listFuelManagement(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFuelManagementSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFuelManagementError(message));
  }
}

function* createFuelManagement(api, { payload }) {
  const response = yield call(api.create, payload.data);

  if (response.ok) {
    yield put(createFuelManagementSuccess());
    yield put(listFuelManagementAction(payload.params));
  } else {
    const message = 'Não foi possível criar este registro';

    yield put(createFuelManagementError(message));
  }
}

function* updateFuelManagement(api, { payload }) {
  const response = yield call(api.update, payload.id, payload.data);

  if (response.ok) {
    yield put(updateFuelManagementSuccess());
    yield put(listFuelManagementAction(payload.params));
  } else {
    const message = 'Não foi possível atualizar este registro';

    yield put(updateFuelManagementError(message));
  }
}

function* deleteFuelManagement(api, { payload }) {
  const response = yield call(api.remove, payload.id);

  if (response.ok) {
    yield put(deleteFuelManagementSuccess());
    yield put(listFuelManagementAction(payload.params));
  } else {
    const message = 'Não foi possível remover este registro';

    yield put(deleteFuelManagementError(message));
  }
}

function* recoverFuelManagement(api, { payload }) {
  const response = yield call(api.recover, payload.id);

  if (response.ok) {
    yield put(recoverFuelManagementSuccess());
    yield put(listFuelManagementAction(payload.params));
  } else {
    const message = 'Não foi possível recuperar este registro';

    yield put(recoverFuelManagementError(message));
  }
}

function* getById(api, { payload }) {
  const response = yield call(api.getById, payload.id);

  if (response.ok) {
    yield put(getFuelManagementByIdSuccess(response.data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(getFuelManagementByIdError(message));
  }
}

export function* watchListFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_LIST_REQUEST, listFuelManagement, api);
}

export function* watchCreateFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_CREATE_REQUEST, createFuelManagement, api);
}

export function* watchUpdateFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_UPDATE_REQUEST, updateFuelManagement, api);
}

export function* watchDeleteFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_DELETE_REQUEST, deleteFuelManagement, api);
}

export function* watchRecoverFuelManagement(api) {
  yield takeEvery(FUEL_MANAGEMENT_RECOVER_REQUEST, recoverFuelManagement, api);
}

export function* watchGetById(api) {
  yield takeEvery(GET_FUEL_MANAGEMENT_BY_ID, getById, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListFuelManagement, api),
    fork(watchCreateFuelManagement, api),
    fork(watchUpdateFuelManagement, api),
    fork(watchDeleteFuelManagement, api),
    fork(watchRecoverFuelManagement, api),
    fork(watchGetById, api),
  ]);
}

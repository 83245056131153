import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/tank/users${objectToURLParams(parameters)}`);

export const getInternalTankOrganization = parameters =>
  api.get(`/admin/organization/tank${objectToURLParams(parameters)}`);

export const create = data => api.post('/admin/tank/user', data);

export const update = (id, data) => api.put(`/admin/tank/user/${id}`, data);

export const remove = id => api.delete(`/admin/tank/user/${id}`);

export const recover = id => api.post(`/admin/tank/user/recover/${id}`);

import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as organizations from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const userOrganizations = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      organizations.onSuccess({ list: userOrganizations, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.onError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível criar a filial';
    yield put(organizations.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível editar a filial';
    yield put(organizations.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível remover a filial';
    yield put(organizations.onError(message));
  }
}

function* recover(api, { payload }) {
  const response = yield call(api.recover, payload.id);

  if (response.ok) {
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível recuperar a empresa';
    yield put(organizations.onError(message));
  }
}

function* addBalance(api, { payload }) {
  const response = yield call(api.addBalance, payload.id, payload.data);

  if (response.ok) {
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível adicionar créditos';
    yield put(organizations.onError(message));
  }
}

function* getById(api, { payload }) {
  const response = yield call(api.getById, payload.organization);

  if (response.ok) {
    yield put(organizations.getOrganizationByIdSuccess(response.data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.onError(message));
  }
}

function* reapBalance(api, { payload }) {
  const response = yield call(api.reapBalance, payload.id);

  if (response.ok) {
    yield put(organizations.reapOrganizationBalanceSuccess());
    yield put(organizations.getOrganizations(payload.params));
  } else {
    const message = 'Não foi possível recolher o saldo';
    yield put(organizations.reapOrganizationBalanceError(message));
  }
}

function* listOrganizationModules(api, { payload }) {
  const { organizationId } = payload;

  const modules = yield call(api.getOrganizationModules, organizationId);

  if (modules.ok) {
    const list = modules.data.docs;
    const { current: currentPage, total: totalPages } = modules.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = modules.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      organizations.listOrganizationModulesSuccess({ list, pages, items })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(organizations.listOrganizationModulesError(message));
  }
}

function* subscribeCustomModule(api, { payload }) {
  const { data } = payload;

  const response = yield call(api.subscribeCustomModule, data);

  if (response.ok) {
    yield put(organizations.subscribeCustomModuleSuccess());
    yield put(organizations.listOrganizationModules(data._organization));
  } else {
    const message = 'Não foi possível assinar o módulo';
    yield put(organizations.subscribeCustomModuleError(message));
  }
}

function* editSubscriptionCustomModule(api, { payload }) {
  const { id, data, organizationId } = payload;

  const response = yield call(api.editSubscriptionCustomModule, id, data);

  if (response.ok) {
    yield put(organizations.editSubscriptionCustomModuleSuccess());
    yield put(organizations.listOrganizationModules(organizationId));
  } else {
    const message = 'Não foi possível editar o módulo';
    yield put(organizations.editSubscriptionCustomModuleError(message));
  }
}

function* watchList(api) {
  yield takeEvery(organizations.ACTIONS.GET_ORGANIZATIONS, list, api);
}

function* watchPost(api) {
  yield takeEvery(organizations.ACTIONS.CREATE_ORGANIZATION, post, api);
}

function* watchPut(api) {
  yield takeEvery(organizations.ACTIONS.EDIT_ORGANIZATION, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(organizations.ACTIONS.DELETE_ORGANIZATION, remove, api);
}

function* watchRecover(api) {
  yield takeEvery(organizations.ACTIONS.RECOVER_ORGANIZATION, recover, api);
}

function* watchAddBalance(api) {
  yield takeEvery(
    organizations.ACTIONS.ADD_ORGANIZATION_BALANCE,
    addBalance,
    api
  );
}

function* watchGetById(api) {
  yield takeEvery(organizations.ACTIONS.GET_ORGANIZATION_BY_ID, getById, api);
}

function* watchReapBalance(api) {
  yield takeEvery(
    organizations.ACTIONS.REAP_ORGANIZATION_BALANCE,
    reapBalance,
    api
  );
}

function* watchListModules(api) {
  yield takeEvery(
    organizations.ACTIONS.ORGANIZATION_MODULE_LIST_REQUEST,
    listOrganizationModules,
    api
  );
}

function* watchSubscribeCustomModule(api) {
  yield takeEvery(
    organizations.ACTIONS.SUBSCRIBE_CUSTOM_MODULE_REQUEST,
    subscribeCustomModule,
    api
  );
}

function* watchEditSubscriptionCustomModule(api) {
  yield takeEvery(
    organizations.ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_REQUEST,
    editSubscriptionCustomModule,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchRecover, api),
    fork(watchAddBalance, api),
    fork(watchGetById, api),
    fork(watchReapBalance, api),
    fork(watchListModules, api),
    fork(watchSubscribeCustomModule, api),
    fork(watchEditSubscriptionCustomModule, api),
  ]);
}

import produce from 'immer';

import { BILLING_STATEMENTS_ACTIONS } from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  loading: false,
  error: null,
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};

export const SUBSCRIPTIONS_LIST_REQUEST = 'SUBSCRIPTIONS_LIST_REQUEST';
export const SUBSCRIPTION_REQUEST_SUCCESS = 'SUBSCRIPTION_REQUEST_SUCCESS';
export const SUBSCRIPTION_REQUEST_ERROR = 'SUBSCRIPTION_REQUEST_ERROR';
export const SUBSCRIPTION_CREATION_REQUEST = 'SUBSCRIPTION_CREATION_REQUEST';
export const SUBSCRIPTION_EDIT_REQUEST = 'SUBSCRIPTION_EDIT_REQUEST';

export const listSubscriptions = organization => ({
  type: SUBSCRIPTIONS_LIST_REQUEST,
  payload: { organization },
});

export const subscriptionRequestSuccess = payload => ({
  type: SUBSCRIPTION_REQUEST_SUCCESS,
  payload,
});

export const subscriptionRequestError = message => ({
  type: SUBSCRIPTION_REQUEST_ERROR,
  payload: { message },
});

export const createSubscription = (data, organization) => ({
  type: SUBSCRIPTION_CREATION_REQUEST,
  payload: { data, organization },
});

export const editSubscription = (id, data, organization) => ({
  type: SUBSCRIPTION_EDIT_REQUEST,
  payload: { id, data, organization },
});

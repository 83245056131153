import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  organizations: [],
  modulesOrganizationList: [],
  modulesOrganization: {
    currentPage: '',
    totalPages: '',
    totalItems: '',
    skipItems: 0,
    loading: false,
    error: '',
    success: false,
  },
  subscribeCustomModule: {
    loading: false,
    error: '',
    success: false,
  },
  editSubscriptionCustomModule: {
    loading: false,
    error: '',
    success: false,
  },
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  success: false,
  loading: false,
  error: null,
  currentOrganization: null,
  organization: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_ORGANIZATIONS: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_SUCCESS: {
        draft.loading = false;
        draft.organizations = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case ACTIONS.ON_ERROR: {
        draft.loading = false;
        draft.organizations = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.SELECT_ORGANIZATION: {
        draft.currentOrganization = action.payload.currentOrganization;
        break;
      }

      case ACTIONS.DESELECT_ORGANIZATION: {
        draft.currentOrganization = null;
        break;
      }

      case ACTIONS.CLEAR_ORGANIZATIONS: {
        draft.organizations = [];
        draft.currentOrganization = null;
        break;
      }

      case ACTIONS.CREATE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.EDIT_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.DELETE_ORGANIZATION: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_ORGANIZATION_BY_ID: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_ORGANIZATION_BY_ID_SUCCESS: {
        draft.loading = false;
        draft.organization = action.payload.organization;
        draft.error = '';
        break;
      }

      case ACTIONS.REAP_ORGANIZATION_BALANCE: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.REAP_ORGANIZATION_BALANCE_SUCCESS: {
        draft.loading = false;
        draft.error = '';
        break;
      }

      case ACTIONS.REAP_ORGANIZATION_BALANCE_ERROR: {
        draft.loading = false;
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.ORGANIZATION_MODULE_LIST_REQUEST: {
        draft.modulesOrganizationList = [];
        draft.modulesOrganization = {
          loading: true,
          currentPage: '',
          totalPages: '',
          totalItems: '',
          skipItems: 0,
          error: '',
        };
        break;
      }
      case ACTIONS.ORGANIZATION_MODULE_LIST_SUCCESS: {
        draft.modulesOrganizationList = action.payload.list;
        draft.modulesOrganization = {
          loading: false,
          currentPage: action.payload.pages.currentPage,
          totalPages: action.payload.pages.totalPages,
          totalItems: action.payload.items.totalItems,
          skipItems: action.payload.items.skipItems,
          error: '',
        };
        break;
      }
      case ACTIONS.ORGANIZATION_MODULE_LIST_ERROR: {
        draft.modulesOrganizationList = [];
        draft.modulesOrganization = {
          loading: false,
          currentPage: '',
          totalPages: '',
          totalItems: '',
          skipItems: 0,
          error: action.payload.message,
        };
        break;
      }
      case ACTIONS.SUBSCRIBE_CUSTOM_MODULE_REQUEST: {
        draft.subscribeCustomModule = {
          loading: true,
          error: '',
          success: false,
        };
        break;
      }
      case ACTIONS.SUBSCRIBE_CUSTOM_MODULE_SUCCESS: {
        draft.subscribeCustomModule = {
          loading: false,
          error: '',
          success: true,
        };
        break;
      }
      case ACTIONS.SUBSCRIBE_CUSTOM_MODULE_ERROR: {
        draft.subscribeCustomModule = {
          loading: false,
          error: action.payload.message,
          success: false,
        };
        break;
      }
      case ACTIONS.RESET_SUBSCRIBE_CUSTOM_MODULE_STATE: {
        draft.subscribeCustomModule = {
          loading: false,
          error: '',
          success: false,
        };
        break;
      }
      case ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_REQUEST: {
        draft.editSubscriptionCustomModule = {
          loading: true,
          error: '',
          success: false,
        };
        break;
      }
      case ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_SUCCESS: {
        draft.editSubscriptionCustomModule = {
          loading: false,
          error: '',
          success: true,
        };
        break;
      }
      case ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_ERROR: {
        draft.editSubscriptionCustomModule = {
          loading: false,
          error: action.payload.message,
          success: false,
        };
        break;
      }
      case ACTIONS.RESET_EDIT_SUBSCRIPTON_CUSTOM_MODULE_STATE: {
        draft.editSubscriptionCustomModule = {
          loading: false,
          error: '',
          success: false,
        };
        break;
      }

      default:
        return state;
    }
  });
};

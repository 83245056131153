import { all } from 'redux-saga/effects';
import api from '../services';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import adminUsersSagas from './adminUsers/saga';
import orgUsersSagas from './orgUsers/saga';
import stationUsersSagas from './stationUsers/saga';
import organizationsSagas from './organizations/saga';
import reportsSagas from './reports/saga';
import mapsSagas from './maps/saga';
import hydrateSagas from './hydrate/saga';
import transactionSagas from './transaction/saga';
import depositsSagas from './deposits/saga';
import moneyTransferSagas from './moneyTransfer/saga';
import gasStationsSagas from './gasStations/saga';
import vehiclesSagas from './vehicles/saga';
import billingStatementsSagas from './billingStatements/saga';
import permissionsSagas from './permissions/saga';
import userPermissionsSagas from './userPermissions/saga';
import discountRateSagas from './discountRate/saga';
import billsSagas from './bills/saga';
import balanceSharingSagas from './balanceSharing/saga';
import gasStationBillingsSagas from './gasStationBillings/saga';
import fuelManagementSagas from './fuelManagement/saga';
import organizationInvoicesSagas from './organizationInvoices/saga';
import purchaseSagas from './purchase/saga';
import moduleSagas from './module/saga';
import subscriptionsSagas from './subscriptions/saga';
import plansSagas from './plans/saga';
import menuPortalSagas from './menuPortal/saga';
import banksSagas from './banks/saga';
import customModulesSagas from './customModules/saga';
import fraudSuspicionsSagas from './fraudSuspicion/saga';
import fuelManagementUsersSagas from './fuelManagementUsers/saga';
import fuelManagementTransactionsSagas from './fuelManagementTransactions/saga';
import gasStationListSagas from './gasStationList/saga';
import paymentAuthorizationSagas from './paymentAuthorization/saga';

export default function* rootSaga() {
  yield all([
    authSagas(api.auth),
    userSagas(),
    adminUsersSagas(api.admins),
    orgUsersSagas(api.user),
    stationUsersSagas(api.stationUsers),
    organizationsSagas(api.organizations),
    reportsSagas(api.report),
    mapsSagas(),
    hydrateSagas(api.hydrate),
    transactionSagas(api.transactions),
    depositsSagas(api.deposits),
    moneyTransferSagas(api.moneyTransfer),
    gasStationsSagas(api.gasStations),
    vehiclesSagas(api.vehicles),
    billingStatementsSagas(api.transactions),
    permissionsSagas(api.permissions),
    userPermissionsSagas(api.userPermissions),
    discountRateSagas(api.discountRate),
    billsSagas(api.bills),
    balanceSharingSagas(api.balanceSharing),
    gasStationBillingsSagas(api.gasStationBillings),
    fuelManagementSagas(api.fuelManagement),
    organizationInvoicesSagas(api.organizationInvoices),
    purchaseSagas(api.purchase),
    moduleSagas(api.module),
    subscriptionsSagas(api.subcriptions),
    plansSagas(api.plans),
    menuPortalSagas(api.menu),
    banksSagas(api.banks),
    customModulesSagas(api.customModules),
    fraudSuspicionsSagas(api.fraudSuspicions),
    fuelManagementUsersSagas(api.fuelManagementUsers),
    fuelManagementTransactionsSagas(api.fuelManagementTransactions),
    gasStationListSagas(api.gasStationList),
    paymentAuthorizationSagas(api.paymentAuthorization),
  ]);
}

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { BANK_LIST_REQUEST, onListError, onListSuccess } from './actions';

function* listBanks(api, { payload }) {
  const banks = yield call(api.list, payload);

  if (banks.ok) {
    const list = banks.data.docs;

    const { current: currentPage, total: totalPages } = banks.data.pages;

    const { total: totalItems, skip: skipItems = 0 } = banks.data.items;

    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(onListSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(onListError(message));
  }
}

export function* watchListBanks(api) {
  yield takeEvery(BANK_LIST_REQUEST, listBanks, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListBanks, api)]);
}

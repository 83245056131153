import produce from 'immer';

import {
  MONEY_TRANSFER_LIST_REQUEST,
  MONEY_TRANSFER_LIST_SUCCESS,
  MONEY_TRANSFER_LIST_ERROR,
} from '../actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MONEY_TRANSFER_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case MONEY_TRANSFER_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case MONEY_TRANSFER_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};

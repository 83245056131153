import produce from 'immer';

import {
  FUEL_MANAGEMENT_LIST_REQUEST,
  FUEL_MANAGEMENT_LIST_SUCCESS,
  FUEL_MANAGEMENT_LIST_ERROR,
  FUEL_MANAGEMENT_CREATE_REQUEST,
  FUEL_MANAGEMENT_CREATE_SUCCESS,
  FUEL_MANAGEMENT_CREATE_ERROR,
  FUEL_MANAGEMENT_RESET,
  FUEL_MANAGEMENT_UPDATE_REQUEST,
  FUEL_MANAGEMENT_UPDATE_SUCCESS,
  FUEL_MANAGEMENT_UPDATE_ERROR,
  FUEL_MANAGEMENT_DELETE_REQUEST,
  FUEL_MANAGEMENT_DELETE_SUCCESS,
  FUEL_MANAGEMENT_DELETE_ERROR,
  FUEL_MANAGEMENT_RECOVER_REQUEST,
  FUEL_MANAGEMENT_RECOVER_SUCCESS,
  FUEL_MANAGEMENT_RECOVER_ERROR,
  GET_FUEL_MANAGEMENT_BY_ID,
  GET_FUEL_MANAGEMENT_BY_ID_SUCCESS,
  GET_FUEL_MANAGEMENT_BY_ID_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  tank: null,
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  fuelManagement: {
    create: false,
    delete: false,
    update: false,
    recover: false,
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FUEL_MANAGEMENT_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case FUEL_MANAGEMENT_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.tank = null;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case FUEL_MANAGEMENT_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.tank = null;
        draft.error = action.payload.message;
        break;
      }
      case FUEL_MANAGEMENT_CREATE_REQUEST: {
        draft.fuelManagement.loading = true;
        draft.fuelManagement.error = '';
        draft.fuelManagement.update = false;
        draft.fuelManagement.delete = false;
        draft.fuelManagement.create = true;
        break;
      }
      case FUEL_MANAGEMENT_CREATE_SUCCESS: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.success = true;
        draft.fuelManagement.error = '';
        break;
      }
      case FUEL_MANAGEMENT_CREATE_ERROR: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.error = action.payload.message;
        break;
      }
      case FUEL_MANAGEMENT_RESET: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.error = '';
        draft.fuelManagement.success = false;
        draft.fuelManagement.create = false;
        draft.fuelManagement.update = false;
        draft.fuelManagement.delete = false;
        draft.fuelManagement.recover = false;
        break;
      }
      case FUEL_MANAGEMENT_UPDATE_REQUEST: {
        draft.fuelManagement.loading = true;
        draft.fuelManagement.error = '';
        draft.fuelManagement.update = true;
        draft.fuelManagement.delete = false;
        draft.fuelManagement.create = false;
        break;
      }
      case FUEL_MANAGEMENT_UPDATE_SUCCESS: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.success = true;
        draft.fuelManagement.error = '';
        break;
      }
      case FUEL_MANAGEMENT_UPDATE_ERROR: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.error = action.payload.message;
        break;
      }
      case FUEL_MANAGEMENT_DELETE_REQUEST: {
        draft.fuelManagement.loading = true;
        draft.fuelManagement.error = '';
        draft.fuelManagement.update = false;
        draft.fuelManagement.delete = true;
        draft.fuelManagement.create = false;
        break;
      }
      case FUEL_MANAGEMENT_DELETE_SUCCESS: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.success = true;
        draft.fuelManagement.error = '';
        break;
      }
      case FUEL_MANAGEMENT_DELETE_ERROR: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.error = action.payload.message;
        break;
      }
      case FUEL_MANAGEMENT_RECOVER_REQUEST: {
        draft.fuelManagement.loading = true;
        draft.fuelManagement.error = '';
        draft.fuelManagement.update = false;
        draft.fuelManagement.delete = false;
        draft.fuelManagement.create = false;
        draft.fuelManagement.recover = true;
        break;
      }
      case FUEL_MANAGEMENT_RECOVER_SUCCESS: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.success = true;
        draft.fuelManagement.error = '';
        break;
      }
      case FUEL_MANAGEMENT_RECOVER_ERROR: {
        draft.fuelManagement.loading = false;
        draft.fuelManagement.error = action.payload.message;
        break;
      }

      case GET_FUEL_MANAGEMENT_BY_ID: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case GET_FUEL_MANAGEMENT_BY_ID_SUCCESS: {
        draft.loading = false;
        draft.tank = action.payload.tank;
        draft.error = '';
        break;
      }

      case GET_FUEL_MANAGEMENT_BY_ID_ERROR: {
        draft.loading = false;
        draft.fuelManagement.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};

import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as gasStations from './actions';

function* list(api, { payload }) {
  console.log('payload', payload);
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    console.log('list', response.data);
    const gasStationList = response.data.docs;

    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems, totalPages };
    yield put(
      gasStations.getGasStationListSuccess({
        list: gasStationList,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(gasStations.getGasStationListError(message));
  }
}

function* watchGasStationList(api) {
  yield takeEvery(
    gasStations.GAS_STATION_LIST_ACTIONS.GET_GAS_STATION_LIST,
    list,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchGasStationList, api)]);
}

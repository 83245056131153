export const ACTIONS = {
  GET_ORGANIZATIONS: 'ADMIN_GET_ORGANIZATIONS',
  ON_SUCCESS: 'ADMIN_SUCCESS',
  ON_ERROR: 'ADMIN_ERROR',
  SELECT_ORGANIZATION: 'ADMIN_SELECT_ORGANIZATION',
  DESELECT_ORGANIZATION: 'ADMIN_DESELECT_ORGANIZATION',
  CLEAR_ORGANIZATIONS: 'ADMIN_CLEAR_ORGANIZATIONS',
  CREATE_ORGANIZATION: 'ADMIN_POST_ORGANIZATION',
  EDIT_ORGANIZATION: 'ADMIN_PUT_ORGANIZATION',
  DELETE_ORGANIZATION: 'ADMIN_DELETE_ORGANIZATION',
  RECOVER_ORGANIZATION: 'ADMIN_RECOVER_ORGANIZATION',
  RECOVER_ORGANIZATION_ERROR: 'ADMIN_RECOVER_ORGANIZATION_ERROR',
  ADD_ORGANIZATION_BALANCE: 'ADMIN_ORGANIZATION_BALANCE',
  ADD_ORGANIZATION_BALANCE_ERROR: 'ADMIN_ORGANIZATION_BALANCE_ERROR',
  GET_ORGANIZATION_BY_ID: 'GET_ORGANIZATION_BY_ID',
  GET_ORGANIZATION_BY_ID_SUCCESS: 'GET_ORGANIZATION_BY_ID_SUCCESS',
  REAP_ORGANIZATION_BALANCE: 'ADMIN_REAP_ORGANIZATION_BALANCE',
  REAP_ORGANIZATION_BALANCE_ERROR: 'ADMIN_REAP_ORGANIZATION_BALANCE_ERROR',
  REAP_ORGANIZATION_BALANCE_SUCCESS: 'ADMIN_REAP_ORGANIZATION_BALANCE_SUCCESS',
  ORGANIZATION_MODULE_LIST_REQUEST: 'ORGANIZATION_MODULE_LIST_REQUEST',
  ORGANIZATION_MODULE_LIST_SUCCESS: 'ORGANIZATION_MODULE_LIST_SUCCESS',
  ORGANIZATION_MODULE_LIST_ERROR: 'ORGANIZATION_MODULE_LIST_ERROR',
  SUBSCRIBE_CUSTOM_MODULE_REQUEST: 'SUBSCRIBE_CUSTOM_MODULE_REQUEST',
  SUBSCRIBE_CUSTOM_MODULE_SUCCESS: 'SUBSCRIBE_CUSTOM_MODULE_SUCCESS',
  SUBSCRIBE_CUSTOM_MODULE_ERROR: 'SUBSCRIBE_CUSTOM_MODULE_ERROR',
  RESET_SUBSCRIBE_CUSTOM_MODULE_STATE: 'RESET_SUBSCRIBE_CUSTOM_MODULE_STATE',
  EDIT_SUBSCRIPTON_CUSTOM_MODULE_REQUEST:
    'EDIT_SUBSCRIPTON_CUSTOM_MODULE_REQUEST',
  EDIT_SUBSCRIPTON_CUSTOM_MODULE_SUCCESS:
    'EDIT_SUBSCRIPTON_CUSTOM_MODULE_SUCCESS',
  EDIT_SUBSCRIPTON_CUSTOM_MODULE_ERROR: 'EDIT_SUBSCRIPTON_CUSTOM_MODULE_ERROR',
  RESET_EDIT_SUBSCRIPTON_CUSTOM_MODULE_STATE:
    'RESET_EDIT_SUBSCRIPTON_CUSTOM_MODULE_STATE',
};

export const getOrganizations = params => ({
  type: ACTIONS.GET_ORGANIZATIONS,
  payload: { params },
});

export const onSuccess = payload => ({
  type: ACTIONS.ON_SUCCESS,
  payload,
});

export const onError = message => ({
  type: ACTIONS.ON_ERROR,
  payload: { message },
});

export const onSelectOrganization = currentOrganization => ({
  type: ACTIONS.SELECT_ORGANIZATION,
  payload: { currentOrganization },
});

export const onDeselectOrganization = () => ({
  type: ACTIONS.DESELECT_ORGANIZATION,
});

export const clearOrganizations = () => ({
  type: ACTIONS.CLEAR_ORGANIZATIONS,
});

export const createOrganization = (organization, params) => ({
  type: ACTIONS.CREATE_ORGANIZATION,
  payload: { organization, params },
});

export const editOrganization = (id, organization, params) => ({
  type: ACTIONS.EDIT_ORGANIZATION,
  payload: { id, organization, params },
});

export const deleteOrganization = (organization, params) => ({
  type: ACTIONS.DELETE_ORGANIZATION,
  payload: { organization, params },
});

export const undeleteOrganizationRequest = (id, params) => ({
  type: ACTIONS.RECOVER_ORGANIZATION,
  payload: { id, params },
});

export const undeleteOrganizationError = message => ({
  type: ACTIONS.RECOVER_ORGANIZATION_ERROR,
  payload: { message },
});

export const addOrganizationBalance = (id, data, params) => ({
  type: ACTIONS.ADD_ORGANIZATION_BALANCE,
  payload: { id, data, params },
});

export const addOrganizationBalanceError = message => ({
  type: ACTIONS.ADD_ORGANIZATION_BALANCE_ERROR,
  payload: { message },
});

export const getOrganizationById = organization => ({
  type: ACTIONS.GET_ORGANIZATION_BY_ID,
  payload: { organization },
});

export const getOrganizationByIdSuccess = organization => ({
  type: ACTIONS.GET_ORGANIZATION_BY_ID_SUCCESS,
  payload: { organization },
});

export const reapOrganizationBalance = (id, params) => ({
  type: ACTIONS.REAP_ORGANIZATION_BALANCE,
  payload: { id, params },
});

export const reapOrganizationBalanceError = message => ({
  type: ACTIONS.REAP_ORGANIZATION_BALANCE_ERROR,
  payload: { message },
});

export const reapOrganizationBalanceSuccess = () => ({
  type: ACTIONS.REAP_ORGANIZATION_BALANCE_SUCCESS,
});

export const listOrganizationModules = organizationId => ({
  type: ACTIONS.ORGANIZATION_MODULE_LIST_REQUEST,
  payload: { organizationId },
});

export const listOrganizationModulesSuccess = payload => ({
  type: ACTIONS.ORGANIZATION_MODULE_LIST_SUCCESS,
  payload,
});

export const listOrganizationModulesError = message => ({
  type: ACTIONS.ORGANIZATION_MODULE_LIST_ERROR,
  payload: { message },
});

export const subscribeCustomModule = data => ({
  type: ACTIONS.SUBSCRIBE_CUSTOM_MODULE_REQUEST,
  payload: { data },
});

export const subscribeCustomModuleSuccess = () => ({
  type: ACTIONS.SUBSCRIBE_CUSTOM_MODULE_SUCCESS,
});

export const subscribeCustomModuleError = message => ({
  type: ACTIONS.SUBSCRIBE_CUSTOM_MODULE_ERROR,
  payload: { message },
});

export const resetSubscribeCustomModuleState = () => ({
  type: ACTIONS.RESET_SUBSCRIBE_CUSTOM_MODULE_STATE,
});

export const editSubscriptionCustomModule = (id, data, organizationId) => ({
  type: ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_REQUEST,
  payload: { id, data, organizationId },
});

export const editSubscriptionCustomModuleSuccess = () => ({
  type: ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_SUCCESS,
});

export const editSubscriptionCustomModuleError = message => ({
  type: ACTIONS.EDIT_SUBSCRIPTON_CUSTOM_MODULE_ERROR,
  payload: { message },
});

export const resetEditSubscriptionCustomModuleState = () => ({
  type: ACTIONS.RESET_EDIT_SUBSCRIPTON_CUSTOM_MODULE_STATE,
});

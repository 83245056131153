export const LIST_PAYMENT_AUTHORIZATION = 'LIST_PAYMENT_AUTHORIZATION';
export const LIST_PAYMENT_AUTHORIZATION_SUCCESS = 'LIST_PAYMENT_AUTHORIZATION_SUCCESS';
export const LIST_PAYMENT_AUTHORIZATION_ERROR = 'LIST_PAYMENT_AUTHORIZATION_ERROR';
export const CREATE_PAYMENT_AUTHORIZATION = 'CREATE_PAYMENT_AUTHORIZATION';
export const CREATE_PAYMENT_AUTHORIZATION_SUCCESS =
  'CREATE_PAYMENT_AUTHORIZATION_SUCCESS';
export const CREATE_PAYMENT_AUTHORIZATION_ERROR = 'CREATE_PAYMENT_AUTHORIZATION_ERROR';
export const UPDATE_PAYMENT_AUTHORIZATION = 'UPDATE_PAYMENT_AUTHORIZATION';
export const UPDATE_PAYMENT_AUTHORIZATION_SUCCESS =
  'UPDATE_PAYMENT_AUTHORIZATION_SUCCESS';
export const UPDATE_PAYMENT_AUTHORIZATION_ERROR = 'REMOVE_PAYMENT_AUTHORIZATION_ERROR';
export const REMOVE_PAYMENT_AUTHORIZATION = 'REMOVE_PAYMENT_AUTHORIZATION';
export const REMOVE_PAYMENT_AUTHORIZATION_SUCCESS =
  'REMOVE_PAYMENT_AUTHORIZATION_SUCCESS';
export const REMOVE_PAYMENT_AUTHORIZATION_ERROR = 'REMOVE_PAYMENT_AUTHORIZATION_ERROR';
export const RECOVER_PAYMENT_AUTHORIZATION = 'RECOVER_PAYMENT_AUTHORIZATION';
export const RECOVER_PAYMENT_AUTHORIZATION_SUCCESS =
  'RECOVER_PAYMENT_AUTHORIZATION_SUCCESS';
export const RECOVER_PAYMENT_AUTHORIZATION_ERROR =
  'RECOVER_PAYMENT_AUTHORIZATION_ERROR';

export const listPaymentAuthorization = params => ({
  type: LIST_PAYMENT_AUTHORIZATION,
  payload: { params },
});

export const onListPaymentAuthorizationSuccess = payload => ({
  type: LIST_PAYMENT_AUTHORIZATION_SUCCESS,
  payload,
});

export const onListPaymentAuthorizationError = message => ({
  type: LIST_PAYMENT_AUTHORIZATION_ERROR,
  payload: { message },
});

export const createPaymentAuthorization = data => ({
  type: CREATE_PAYMENT_AUTHORIZATION,
  payload: { data },
});

export const onCreatePaymentAuthorizationSuccess = () => ({
  type: CREATE_PAYMENT_AUTHORIZATION_SUCCESS,
});

export const onCreatePaymentAuthorizationError = message => ({
  type: CREATE_PAYMENT_AUTHORIZATION_ERROR,
  payload: { message },
});

export const updatePaymentAuthorization = (id, data) => ({
  type: UPDATE_PAYMENT_AUTHORIZATION,
  payload: { id, data },
});

export const onUpdatePaymentAuthorizationSuccess = () => ({
  type: UPDATE_PAYMENT_AUTHORIZATION_SUCCESS,
});

export const onUpdatePaymentAuthorizationError = message => ({
  type: UPDATE_PAYMENT_AUTHORIZATION_ERROR,
  payload: { message },
});

export const removePaymentAuthorization = id => ({
  type: REMOVE_PAYMENT_AUTHORIZATION,
  payload: { id },
});

export const onRemovePaymentAuthorizationSuccess = () => ({
  type: REMOVE_PAYMENT_AUTHORIZATION_SUCCESS,
});

export const onRemovePaymentAuthorizationError = message => ({
  type: REMOVE_PAYMENT_AUTHORIZATION_ERROR,
  payload: { message },
});

export const recoverPaymentAuthorization = id => ({
  type: RECOVER_PAYMENT_AUTHORIZATION,
  payload: { id },
});

export const onRecoverPaymentAuthorizationSuccess = () => ({
  type: RECOVER_PAYMENT_AUTHORIZATION_SUCCESS,
});

export const onRecoverPaymentAuthorizationError = message => ({
  type: RECOVER_PAYMENT_AUTHORIZATION_ERROR,
  payload: { message },
});

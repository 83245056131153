import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const getUsers = parameters =>
  api.get(`/v2/admin/user${objectToURLParams(parameters)}`);

export const getUserPermission = parameters =>
  api.get(`/user/permissions${objectToURLParams(parameters)}`);

export const createUserPermission = payload =>
  api.post('/userPermission', { ...payload });

export const updateUserPermission = (id, status) =>
  api.put(`/userPermission/${id}`, { status });

export const getUserPermissionsByUserId = userId =>
  api.get(`/user/permissions/${userId}`);

export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST';
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS';
export const TRANSACTION_LIST_ERROR = 'TRANSACTION_LIST_ERROR';
export const TRANSACTION_CANCEL_REQUEST = 'TRANSACTION_CANCEL_REQUEST';
export const TRANSACTION_CANCEL_SUCCESS = 'TRANSACTION_CANCEL_SUCCESS';
export const TRANSACTION_CANCEL_ERROR = 'TRANSACTION_CANCEL_ERROR';
export const TRANSACTION_CANCEL_RESET_STATE = 'TRANSACTION_CANCEL_RESET_STATE';
export const TRANSACTION_ADD_NOTES_REQUEST = 'TRANSACTION_ADD_NOTES_REQUEST';
export const TRANSACTION_ADD_NOTES_SUCCESS = 'TRANSACTION_ADD_NOTES_SUCCESS';
export const TRANSACTION_ADD_NOTES_ERROR = 'TRANSACTION_ADD_NOTES_ERROR';
export const TRANSACTION_ADD_NOTES_RESET_STATE =
  'TRANSACTION_ADD_NOTES_RESET_STATE';
export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_ERROR = 'CREATE_TRANSACTION_ERROR';
export const CREATE_TRASACTION_RESET_STATE = 'CREATE_TRASACTION_RESET_STATE';

export const listTransaction = params => ({
  type: TRANSACTION_LIST_REQUEST,
  payload: { params },
});

export const listTransactionSuccess = payload => ({
  type: TRANSACTION_LIST_SUCCESS,
  payload,
});

export const listTransactionError = message => ({
  type: TRANSACTION_LIST_ERROR,
  payload: { message },
});

export const cancelTransaction = (id, body) => ({
  type: TRANSACTION_CANCEL_REQUEST,
  payload: { id, body },
});

export const cancelTransactionSuccess = payload => ({
  type: TRANSACTION_CANCEL_SUCCESS,
  payload,
});

export const cancelTransactionError = message => ({
  type: TRANSACTION_CANCEL_ERROR,
  payload: { message },
});

export const cancelTransactionResetState = () => ({
  type: TRANSACTION_CANCEL_RESET_STATE,
});

export const addNotesTransaction = (id, body) => ({
  type: TRANSACTION_ADD_NOTES_REQUEST,
  payload: { id, body },
});

export const addNotesTransactionSuccess = payload => ({
  type: TRANSACTION_ADD_NOTES_SUCCESS,
  payload,
});

export const addNotesTransactionError = message => ({
  type: TRANSACTION_ADD_NOTES_ERROR,
  payload: { message },
});

export const addNotesTransactionResetState = () => ({
  type: TRANSACTION_ADD_NOTES_RESET_STATE,
});

export const createTransaction = (body, params) => ({
  type: CREATE_TRANSACTION_REQUEST,
  payload: { body, params },
});

export const createTransactionSuccess = payload => ({
  type: CREATE_TRANSACTION_SUCCESS,
  payload,
});

export const createTransactionError = message => ({
  type: CREATE_TRANSACTION_ERROR,
  payload: { message },
});

export const createTransactionResetState = () => ({
  type: CREATE_TRASACTION_RESET_STATE,
});

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  listGasStationBillingsSuccess,
  listGasStationBillingsError,
  GAS_STATION_BILLINGS_LIST_REQUEST,
  GET_TRANSACTIONS_REQUEST,
  GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST,
  getTransactionsSuccess,
  getTransactionsError,
  updateStatusSuccess,
  updateStatusError,
  listGasStationBillings as listGasStationBillingsAction,
  GAS_STATION_BILLINGS_REPROCESS_REQUEST,
  reprocessBillingsSuccess,
  reprocessBillingsError,
  SCHEDULE_GAS_STATION_BILLINGS_REQUEST,
  scheduleGasStationBillingsSuccess,
  scheduleGasStationBillingsError,
  getPaymentHistoryError,
  getPaymentHistorySuccess,
  GET_PAYMENT_HISTORY_REQUEST,
} from './actions';

function* listGasStationBillings(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listGasStationBillingsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listGasStationBillingsError(message));
  }
}

function* getTransactions(api, { payload }) {
  const response = yield call(api.getTransactions, APIParams(payload.params));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(getTransactionsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(getTransactionsError(message));
  }
}

function* updateStatus(api, { payload }) {
  const response = yield call(api.updateStatus, payload.id, payload.data);

  if (response.ok) {
    yield put(updateStatusSuccess(response.data));
    yield put(listGasStationBillingsAction(payload.params));
  } else {
    const message = 'Não foi possível atualizar o pagamento';

    yield put(updateStatusError(message));
  }
}

function* reprocessBillings(api, { payload }) {
  const response = yield call(api.reprocessBillings, payload.data);

  if (response.ok) {
    yield put(reprocessBillingsSuccess(response));
    yield put(listGasStationBillingsAction(payload.params));
  } else {
    const message = 'Não foi possível reprocessar os pagamentos';

    yield put(reprocessBillingsError(message));
  }
}

function* scheduleBilling(api, { payload }) {
  const response = yield call(api.scheduleBilling, payload.id);

  if (response.ok) {
    yield put(scheduleGasStationBillingsSuccess(response.data));
  } else {
    const message = 'Não foi possível reprocessar os pagamentos';

    yield put(scheduleGasStationBillingsError(message));
  }
}

function* getPaymentHistory(api, { payload }) {
  const response = yield call(api.getPaymentHistory, APIParams(payload.params));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(getPaymentHistorySuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(getPaymentHistoryError(message));
  }
}

export function* watchListGasStationBillings(api) {
  yield takeEvery(
    GAS_STATION_BILLINGS_LIST_REQUEST,
    listGasStationBillings,
    api
  );
}

export function* watchGetTransactions(api) {
  yield takeEvery(GET_TRANSACTIONS_REQUEST, getTransactions, api);
}

export function* watchUpdateStatus(api) {
  yield takeEvery(
    GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST,
    updateStatus,
    api
  );
}

export function* watchReprocessBillings(api) {
  yield takeEvery(
    GAS_STATION_BILLINGS_REPROCESS_REQUEST,
    reprocessBillings,
    api
  );
}

export function* watchScheduleBilling(api) {
  yield takeEvery(SCHEDULE_GAS_STATION_BILLINGS_REQUEST, scheduleBilling, api);
}

export function* watchGetPaymentHistory(api) {
  yield takeEvery(GET_PAYMENT_HISTORY_REQUEST, getPaymentHistory, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListGasStationBillings, api),
    fork(watchGetTransactions, api),
    fork(watchUpdateStatus, api),
    fork(watchReprocessBillings, api),
    fork(watchScheduleBilling, api),
    fork(watchGetPaymentHistory, api),
  ]);
}

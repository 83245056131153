import produce from 'immer';

import {
  MENU_LIST_REQUEST,
  MENU_LIST_ERROR,
  MENU_LIST_SUCCESS,
  CREATE_MENU,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_ERROR,
  EDIT_MENU,
  EDIT_MENU_SUCCESS,
  EDIT_MENU_ERROR,
  DELETE_MENU,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MENU_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case MENU_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case MENU_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case CREATE_MENU: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CREATE_MENU_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case CREATE_MENU_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      case EDIT_MENU: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case EDIT_MENU_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case EDIT_MENU_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      case DELETE_MENU: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case DELETE_MENU_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case DELETE_MENU_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};

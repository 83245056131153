import produce from 'immer';

import {
  MODULE_LIST_REQUEST,
  MODULE_LIST_ERROR,
  MODULE_LIST_SUCCESS,
  CREATE_MODULE,
  CREATE_MODULE_SUCCESS,
  CREATE_MODULE_ERROR,
  EDIT_MODULE,
  EDIT_MODULE_SUCCESS,
  EDIT_MODULE_ERROR,
  DELETE_MODULE,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_ERROR,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case MODULE_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case MODULE_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }
      case MODULE_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }
      case CREATE_MODULE: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case CREATE_MODULE_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case CREATE_MODULE_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      case EDIT_MODULE: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case EDIT_MODULE_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case EDIT_MODULE_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      case DELETE_MODULE: {
        draft.loading = true;
        draft.error = '';
        break;
      }
      case DELETE_MODULE_SUCCESS: {
        draft.loading = false;
        draft.success = true;
        draft.error = '';
        break;
      }
      case DELETE_MODULE_ERROR: {
        draft.loading = false;
        draft.success = false;
        draft.error = action.payload.message;
        break;
      }
      default:
        return state;
    }
  });
};

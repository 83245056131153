// import fetchJsonp from 'fetch-jsonp';
import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/gasStation${objectToURLParams(parameters)}`);

export const post = payload => api.post('/admin/gasStation', payload);

export const put = (id, payload) => api.put(`/gasStation/${id}`, payload);

export const getById = id => api.get(`/gasStation/${id}`);

export const remove = id => api.delete(`/gasStation/${id}`);

export const makePayment = payload => api.post(`/moneyTransfer`, payload);

export const getGasStationByFederalTaxNumber = federalTaxNumber =>
  api.get(`/admin/gasStation/verify/${federalTaxNumber}`);

export const listWithoutReceivables = parameters =>
  api.get(`/v2/admin/gasStation${objectToURLParams(parameters)}`);

export const listWithoutBlackList = (orgId, parameters) =>
  api.get(
    `/admin/gasStation/organization/${orgId}${objectToURLParams(parameters)}`
  );

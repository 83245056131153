export const ADMIN_USER_ACTIONS = {
  ADMIN_GET_USERS_REQUEST: 'ADMIN_GET_USERS_REQUEST',
  ADMIN_GET_USERS_SUCCESS: 'ADMIN_GET_USERS_SUCCESS',
  ADMIN_GET_USERS_ERROR: 'ADMIN_GET_USERS_ERROR',
  ADMIN_CREATE_USER: 'ADMIN_CREATE_USER',
  ADMIN_EDIT_USER: 'ADMIN_EDIT_USER',
  ADMIN_DELETE_USER: 'ADMIN_DELETE_USER',
  ADMIN_UNDELETE_USER: 'ADMIN_UNDELETE_USER',
  ADMIN_UNDELETE_USER_ERROR: 'ADMIN_UNDELETE_USER_ERROR',
};

export const getAdminUsersRequest = params => ({
  type: ADMIN_USER_ACTIONS.ADMIN_GET_USERS_REQUEST,
  payload: { params },
});

export const getAdminUsersSuccess = users => ({
  type: ADMIN_USER_ACTIONS.ADMIN_GET_USERS_SUCCESS,
  payload: { users },
});

export const getAdminUsersError = message => ({
  type: ADMIN_USER_ACTIONS.ADMIN_GET_USERS_ERROR,
  payload: { message },
});

export const createAdminUserRequest = (user, params) => ({
  type: ADMIN_USER_ACTIONS.ADMIN_CREATE_USER,
  payload: { user, params },
});

export const editAdminUserRequest = (id, user, params) => ({
  type: ADMIN_USER_ACTIONS.ADMIN_EDIT_USER,
  payload: { id, user, params },
});

export const deleteAdminUserRequest = (user, params) => ({
  type: ADMIN_USER_ACTIONS.ADMIN_DELETE_USER,
  payload: { user, params },
});

export const undeleteAdminUserRequest = (id, params) => ({
  type: ADMIN_USER_ACTIONS.ADMIN_UNDELETE_USER,
  payload: { id, params },
});

export const undeleteAdminUserError = message => ({
  type: ADMIN_USER_ACTIONS.ADMIN_UNDELETE_USER_ERROR,
  payload: { message },
});

export const PLANS_LIST_REQUEST = 'PLANS_LIST_REQUEST';
export const PLANS_REQUEST_SUCCESS = 'PLANS_REQUEST_SUCCESS';
export const PLANS_REQUEST_ERROR = 'PLANS_REQUEST_ERROR';
export const PLAN_CREATION_REQUEST = 'PLAN_CREATION_REQUEST';
export const PLAN_EDIT_REQUEST = 'PLAN_EDIT_REQUEST';
export const PLAN_DELETE_REQUEST = 'PLAN_DELETE_REQUEST';

export const listPlans = params => ({
  type: PLANS_LIST_REQUEST,
  payload: { params },
});

export const planRequestSuccess = payload => ({
  type: PLANS_REQUEST_SUCCESS,
  payload,
});

export const planRequestError = message => ({
  type: PLANS_REQUEST_ERROR,
  payload: { message },
});

export const createPlan = (data, params) => ({
  type: PLAN_CREATION_REQUEST,
  payload: { data, params },
});

export const editPlan = (id, data, params) => ({
  type: PLAN_EDIT_REQUEST,
  payload: { id, data, params },
});

export const deletePlan = (id, params) => ({
  type: PLAN_DELETE_REQUEST,
  payload: { id, params },
});

import produce from 'immer';
import {
  SUBSCRIPTIONS_LIST_REQUEST,
  SUBSCRIPTION_CREATION_REQUEST,
  SUBSCRIPTION_EDIT_REQUEST,
  SUBSCRIPTION_REQUEST_ERROR,
  SUBSCRIPTION_REQUEST_SUCCESS,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SUBSCRIPTIONS_LIST_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case SUBSCRIPTION_REQUEST_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      case SUBSCRIPTION_REQUEST_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      case SUBSCRIPTION_CREATION_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;
      case SUBSCRIPTION_EDIT_REQUEST:
        draft.loading = true;
        draft.error = '';
        break;

      default:
        return state;
    }
  });
};

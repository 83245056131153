export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST';
export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS';
export const FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR =
  'FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR';

export const listFuelManagementTransactions = params => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementTransactionsSuccess = payload => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const listFuelManagementTransactionsError = message => ({
  type: FUEL_MANAGEMENT_TRANSACTIONS_LIST_ERROR,
  payload: { message },
});

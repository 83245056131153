export const ACTIONS = {
  GET_DISCOUNTS: 'GET_DISCOUNTS',
  GET_DISCOUNTS_SUCCESS: 'GET_DISCOUNTS_SUCCESS',
  GET_DISCOUNTS_ERROR: 'GET_DISCOUNTS_ERROR',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  EDIT_DISCOUNT: 'EDIT_DISCOUNT',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  GET_DISCOUNT_BY_ID: 'GET_DISCOUNT_BY_ID',
  GET_DISCOUNT_BY_ID_SUCCESS: 'GET_DISCOUNT_BY_ID_SUCCESS',
  GET_TRANSACTION_DISCOUNT: 'GET_TRANSACTION_DISCOUNT',
  GET_TRANSACTION_DISCOUNT_SUCCESS: 'GET_TRANSACTION_DISCOUNT_SUCCESS',
  GET_TRANSACTION_DISCOUNT_ERROR: 'GET_TRANSACTION_DISCOUNT_ERROR',
};

export const getDiscounts = params => ({
  type: ACTIONS.GET_DISCOUNTS,
  payload: { params },
});

export const onSuccess = discounts => ({
  type: ACTIONS.GET_DISCOUNTS_SUCCESS,
  payload: { discounts },
});

export const onError = message => ({
  type: ACTIONS.GET_DISCOUNTS_ERROR,
  payload: { message },
});

export const createDiscount = (discount, params) => ({
  type: ACTIONS.CREATE_DISCOUNT,
  payload: { discount, params },
});

export const editDiscount = (id, discount, params) => ({
  type: ACTIONS.EDIT_DISCOUNT,
  payload: { id, discount, params },
});

export const deleteDiscount = (discount, params) => ({
  type: ACTIONS.DELETE_DISCOUNT,
  payload: { discount, params },
});

export const getDiscountById = discount => ({
  type: ACTIONS.GET_DISCOUNT_BY_ID,
  payload: { discount },
});

export const getDiscountByIdSuccess = discount => ({
  type: ACTIONS.GET_DISCOUNT_BY_ID_SUCCESS,
  payload: { discount },
});

export const getTransactionDiscount = ({ stationId, orgId }) => ({
  type: ACTIONS.GET_TRANSACTION_DISCOUNT,
  payload: { orgId, stationId },
});

export const getTransactionDiscountSuccess = discount => ({
  type: ACTIONS.GET_TRANSACTION_DISCOUNT_SUCCESS,
  payload: { discount },
});

export const getTransactionDiscountError = message => ({
  type: ACTIONS.GET_TRANSACTION_DISCOUNT_ERROR,
  payload: { message },
});

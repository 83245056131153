export const FRAUD_SUSPICION_LIST_REQUEST = 'FRAUD_SUSPICION_LIST_REQUEST';
export const FRAUD_SUSPICION_LIST_SUCCESS = 'FRAUD_SUSPICION_LIST_SUCCESS';
export const FRAUD_SUSPICION_LIST_ERROR = 'FRAUD_SUSPICION_LIST_ERROR';
export const FRAUD_SUSPICION_CHANGE_STATUS_REQUEST =
  'FRAUD_SUSPICION_CHANGE_STATUS_REQUEST';
export const FRAUD_SUSPICION_CHANGE_STATUS_SUCCESS =
  'FRAUD_SUSPICION_CHANGE_STATUS_SUCCESS';
export const FRAUD_SUSPICION_CHANGE_STATUS_ERROR =
  'FRAUD_SUSPICION_CHANGE_STATUS_ERROR';
export const FRAUD_SUSPICION_RESET_CHANGE_STATUS_STATE =
  'FRAUD_SUSPICION_RESET_CHANGE_STATUS_STATE';

export const listFraudSuspicions = params => ({
  type: FRAUD_SUSPICION_LIST_REQUEST,
  payload: { params },
});

export const listFraudSuspicionsSuccess = payload => ({
  type: FRAUD_SUSPICION_LIST_SUCCESS,
  payload,
});

export const listFraudSuspicionsError = message => ({
  type: FRAUD_SUSPICION_LIST_ERROR,
  payload: { message },
});

export const changeStatusFraudSuspicion = (id, data, params) => ({
  type: FRAUD_SUSPICION_CHANGE_STATUS_REQUEST,
  payload: { id, data, params },
});

export const changeStatusFraudSuspicionSuccess = payload => ({
  type: FRAUD_SUSPICION_CHANGE_STATUS_SUCCESS,
  payload,
});

export const changeStatusFraudSuspicionError = message => ({
  type: FRAUD_SUSPICION_CHANGE_STATUS_ERROR,
  payload: { message },
});

export const resetChangeStatusFraudSuspicionState = () => ({
  type: FRAUD_SUSPICION_RESET_CHANGE_STATUS_STATE,
});

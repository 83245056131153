import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/tank${objectToURLParams(parameters)}`);

export const getById = id => api.get(`/admin/tank/${id}`);

export const create = data => api.post('/admin/tank', data);

export const update = (id, data) => api.put(`/admin/tank/${id}`, data);

export const remove = id => api.delete(`/admin/tank/${id}`);

export const recover = id => api.post(`/admin/tank/recover/${id}`);

export const getOrganizationContainer = parameters =>
  api.get(`/admin/organization/container${objectToURLParams(parameters)}`);

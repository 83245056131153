import produce from 'immer';

import { GAS_STATION_LIST_ACTIONS } from './actions';

const INIT_STATE = {
  gasStationList: [],
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  skipItems: 0,
  success: false,
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GAS_STATION_LIST_ACTIONS.GET_GAS_STATION_LIST: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case GAS_STATION_LIST_ACTIONS.GAS_STATION_LIST_ON_SUCCESS: {
        draft.loading = false;
        draft.gasStationList = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = null;
        break;
      }

      case GAS_STATION_LIST_ACTIONS.GAS_STATION_LIST_ON_ERROR: {
        draft.loading = false;
        draft.gasStationList = [];
        draft.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};

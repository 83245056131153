import produce from 'immer';

import { ACTIONS } from './actions';

const INIT_STATE = {
  discounts: [],
  loading: false,
  error: '',
  discount: null,
  transaction: {
    discount: null,
    loading: false,
    error: '',
  },
};

export default (state = INIT_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTIONS.GET_DISCOUNTS: {
        draft.loading = true;
        draft.error = null;
        break;
      }

      case ACTIONS.GET_DISCOUNTS_SUCCESS: {
        draft.loading = false;
        draft.discounts = action.payload.discounts;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_DISCOUNTS_ERROR: {
        draft.loading = false;
        draft.discounts = [];
        draft.error = action.payload.message;
        break;
      }

      case ACTIONS.CREATE_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.EDIT_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.DELETE_DISCOUNT: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_DISCOUNT_BY_ID: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_DISCOUNT_BY_ID_SUCCESS: {
        draft.loading = false;
        draft.discount = action.payload.discount;
        draft.error = '';
        break;
      }

      case ACTIONS.GET_TRANSACTION_DISCOUNT: {
        draft.transaction.value = null;
        draft.transaction.loading = true;
        draft.transaction.error = null;
        break;
      }

      case ACTIONS.GET_TRANSACTION_DISCOUNT_SUCCESS: {
        draft.transaction.loading = false;
        draft.transaction.discount = action.payload.discount;
        draft.transaction.error = '';
        break;
      }

      case ACTIONS.GET_TRANSACTION_DISCOUNT_ERROR: {
        draft.transaction.loading = false;
        draft.transaction.value = null;
        draft.transaction.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });

import { all, put, call, takeEvery, fork } from 'redux-saga/effects';

import {
  MENU_LIST_REQUEST,
  DELETE_MENU,
  CREATE_MENU,
  EDIT_MENU,
  listMenuSuccess,
  listMenuError,
  getMenu,
  createMenuSuccess,
  createMenuError,
  editMenuSuccess,
  editMenuError,
  deleteMenuSuccess,
  deleteMenuError,
} from './actions';

function* listMenus(api, { payload }) {
  const modules = yield call(api.list, payload.params);

  if (modules.ok) {
    const list = modules.data.docs;
    const { current: currentPage, total: totalPages } = modules.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = modules.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listMenuSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listMenuError(message));
  }
}

function* createMenu(api, { payload }) {
  const { module: data, params } = payload;
  const response = yield call(api.create, data);

  if (response.ok) {
    yield put(createMenuSuccess(response.data));
    yield put(getMenu(params));
  } else {
    const message = 'Não foi possível criar este módulo';
    yield put(createMenuError(message));
  }
}

function* editMenu(api, { payload }) {
  const { id, module: data, params } = payload;
  const response = yield call(api.edit, id, data);

  if (response.ok) {
    yield put(editMenuSuccess(response.data));
    yield put(getMenu(params));
  } else {
    const message = 'Não foi possível editar este módulo';
    yield put(editMenuError(message));
  }
}

function* deleteMenu(api, { payload }) {
  const { module: data, params } = payload;
  const response = yield call(api.remove, data._id);

  if (response.ok) {
    yield put(deleteMenuSuccess(response.data));
    yield put(getMenu(params));
  } else {
    const message = 'Não foi possível deletar este módulo';
    yield put(deleteMenuError(message));
  }
}

export function* watchListMenus(api) {
  yield takeEvery(MENU_LIST_REQUEST, listMenus, api);
}

export function* watchCreateMenu(api) {
  yield takeEvery(CREATE_MENU, createMenu, api);
}

export function* watchEditMenu(api) {
  yield takeEvery(EDIT_MENU, editMenu, api);
}

export function* watchDeleteMenu(api) {
  yield takeEvery(DELETE_MENU, deleteMenu, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListMenus, api),
    fork(watchCreateMenu, api),
    fork(watchEditMenu, api),
    fork(watchDeleteMenu, api),
  ]);
}

export const ACTIONS = {
  LIST_BALANCE_SHARING: 'LIST_BALANCE_SHARING',
  LIST_BALANCE_SHARING_SUCCESS: 'LIST_BALANCE_SHARING_SUCCESS',
  LIST_BALANCE_SHARING_ERROR: 'LIST_BALANCE_SHARING_ERROR',
};

export const listBalanceSharing = params => ({
  type: ACTIONS.LIST_BALANCE_SHARING,
  payload: { params },
});

export const onListSuccess = payload => ({
  type: ACTIONS.LIST_BALANCE_SHARING_SUCCESS,
  payload,
});

export const onListError = payload => ({
  type: ACTIONS.LIST_BALANCE_SHARING_ERROR,
  payload,
});

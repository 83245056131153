import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/deposits${objectToURLParams(parameters)}`);

export const listAttachments = parameters =>
  api.get(`/admin/depositAttachment${objectToURLParams(parameters)}`);

export const downloadAttachment = parameters =>
  api.get(`/download${objectToURLParams(parameters)}`);

export const postAttachment = payload =>
  api.post('/admin/depositAttachment', payload);

export const removeAttachment = id => api.delete(`/depositAttachments/${id}`);

export const cancelDeposit = (id, payload) =>
  api.post(`/admin/organization/deposit/${id}/refund-credit`, payload);

export const LIST_CUSTOM_MODULES_REQUEST = 'LIST_CUSTOM_MODULES_REQUEST';
export const LIST_CUSTOM_MODULES_SUCCESS = 'LIST_CUSTOM_MODULES_SUCCESS';
export const LIST_CUSTOM_MODULES_ERROR = 'LIST_CUSTOM_MODULES_ERROR';

export const listCustomModulesRequest = params => ({
  type: LIST_CUSTOM_MODULES_REQUEST,
  payload: params,
});

export const listCustomModulesSuccess = payload => ({
  type: LIST_CUSTOM_MODULES_SUCCESS,
  payload,
});

export const listCustomModulesError = message => ({
  type: LIST_CUSTOM_MODULES_ERROR,
  payload: { message },
});

import fetchJsonp from 'fetch-jsonp';
import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/organization${objectToURLParams(parameters)}`);

export const post = payload => api.post('/admin/organization', payload);

export const put = (id, payload) =>
  api.put(`/admin/organization/${id}`, payload);

export const remove = id => api.delete(`/organization/${id}`);

export const getById = id => api.get(`/admin/organization/${id}`);

export const reapBalance = id =>
  api.post(`/admin/organization/${id}/reap-balance`);

export const addBalance = (id, payload) =>
  api.put(`/organization/${id}/balance`, payload);

export const recover = (id, payload) =>
  api.put(`/organization/recover/${id}`, payload);

export const getDataByFederalTax = async federalTaxNumber => {
  const url = `https://www.receitaws.com.br/v1/cnpj/${federalTaxNumber.replace(
    /[./-]/g,
    ''
  )}`;

  const request = await fetchJsonp(url);
  const json = await request.json();
  return json;
};

export const getOrganizationModules = organizationId =>
  api.get(`/admin/organization/${organizationId}/modules`);

export const subscribeCustomModule = data =>
  api.post('/admin/organization/modules', data);

export const editSubscriptionCustomModule = (id, data) =>
  api.put(`/admin/organization/modules/${id}`, data);

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as balanceSharing from './actions';

function* listBalanceSharing(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(balanceSharing.onListSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(balanceSharing.onListError({ message }));
  }
}

export function* watchlistBalanceSharing(api) {
  yield takeEvery(
    balanceSharing.ACTIONS.LIST_BALANCE_SHARING,
    listBalanceSharing,
    api
  );
}

export default function* rootSaga(api) {
  yield all([fork(watchlistBalanceSharing, api)]);
}

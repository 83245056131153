export const ACTIONS = {
  BILL_LIST_REQUEST: 'BILL_LIST_REQUEST',
  BILL_LIST_SUCCESS: 'BILL_LIST_SUCCESS',
  BILL_LIST_ERROR: 'BILL_LIST_ERROR',
  BILLS_PER_PERIOD: 'BILLS_PER_PERIOD',
  BILLS_PER_PERIOD_SUCCESS: 'BILLS_PER_PERIOD_SUCCESS',
  BILLS_PER_PERIOD_ERROR: 'BILLS_PER_PERIOD_ERROR',
};

export const listBills = params => ({
  type: ACTIONS.BILL_LIST_REQUEST,
  payload: { params },
});

export const onListSuccess = data => ({
  type: ACTIONS.BILL_LIST_SUCCESS,
  payload: { data },
});

export const onListError = message => ({
  type: ACTIONS.BILL_LIST_ERROR,
  payload: { message },
});

export const listBillsPerPeriod = params => ({
  type: ACTIONS.BILLS_PER_PERIOD,
  payload: { params },
});

export const onListPerPeriodSuccess = data => ({
  type: ACTIONS.BILLS_PER_PERIOD_SUCCESS,
  payload: { data },
});

export const onListPerPeriodError = message => ({
  type: ACTIONS.BILLS_PER_PERIOD_ERROR,
  payload: { message },
});

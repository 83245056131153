import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/module${objectToURLParams(parameters)}`);

export const create = data => api.post('/module', data);

export const edit = (id, data) => api.put(`/module/${id}`, data);

export const remove = id => api.delete(`/module/${id}`);

export const PURCHASE_LIST_REQUEST = 'PURCHASE_LIST_REQUEST';
export const PURCHASE_LIST_SUCCESS = 'PURCHASE_LIST_SUCCESS';
export const PURCHASE_LIST_ERROR = 'PURCHASE_LIST_ERROR';
export const PURCHASE_REPROCESS_REQUEST = 'PURCHASE_REPROCESS_REQUEST';
export const PURCHASE_REPROCESS_SUCCESS = 'PURCHASE_REPROCESS_SUCCESS';
export const PURCHASE_REPROCESS_ERROR = 'PURCHASE_REPROCESS_ERROR';
export const PURCHASE_REPROCESS_RESET = 'PURCHASE_REPROCESS_RESET';

export const listPurchase = params => ({
  type: PURCHASE_LIST_REQUEST,
  payload: { params },
});

export const listPurchaseSuccess = payload => ({
  type: PURCHASE_LIST_SUCCESS,
  payload,
});

export const listPurchaseError = message => ({
  type: PURCHASE_LIST_ERROR,
  payload: { message },
});

export const reprocessPurchase = params => ({
  type: PURCHASE_REPROCESS_REQUEST,
  payload: { params },
});

export const reprocessPurchaseSuccess = payload => ({
  type: PURCHASE_REPROCESS_SUCCESS,
  payload,
});

export const reprocessPurchaseError = message => ({
  type: PURCHASE_REPROCESS_ERROR,
  payload: { message },
});

export const reprocessPurchaseReset = () => ({
  type: PURCHASE_REPROCESS_RESET,
});

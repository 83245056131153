export const ACTIONS = {
  GET_VEHICLE: 'ADMIN_GET_VEHICLE',
  VEHICLE_ON_SUCCESS: 'VEHICLE_ON_SUCCESS',
  VEHICLE_ON_ERROR: 'VEHICLE_ON_ERROR',
  CREATE_VEHICLE: 'ADMIN_POST_VEHICLE',
  EDIT_VEHICLE: 'ADMIN_PUT_VEHICLE',
  DELETE_VEHICLE: 'ADMIN_DELETE_VEHICLE',
  UNDELETE_VEHICLE: 'ADMIN_UNDELETE_VEHICLE',
  UNDELETE_VEHICLE_ERROR: 'ADMIN_UNDELETE_VEHICLE_ERROR',
};

export const getVehicles = params => ({
  type: ACTIONS.GET_VEHICLE,
  payload: { params },
});

export const onSuccess = payload => ({
  type: ACTIONS.VEHICLE_ON_SUCCESS,
  payload,
});

export const onError = message => ({
  type: ACTIONS.VEHICLE_ON_ERROR,
  payload: { message },
});

export const createVehicle = (vehicles, params) => ({
  type: ACTIONS.CREATE_VEHICLE,
  payload: { vehicles, params },
});

export const editVehicle = (id, vehicle, params) => ({
  type: ACTIONS.EDIT_VEHICLE,
  payload: { id, vehicle, params },
});

export const deleteVehicle = (vehicle, params) => ({
  type: ACTIONS.DELETE_VEHICLE,
  payload: { vehicle, params },
});

export const undeleteVehicleRequest = (id, params) => ({
  type: ACTIONS.UNDELETE_VEHICLE,
  payload: { id, params },
});

export const undeleteVehicleError = message => ({
  type: ACTIONS.UNDELETE_VEHICLE_ERROR,
  payload: { message },
});

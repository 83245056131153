import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import { HYDRATE_REQUEST, hydrateSuccess, hydrateError } from './actions';

function* hydrate(api, { payload }) {
  const hydrateData = yield call(
    api.hydrate,
    APIParams({
      ...payload.params,
    })
  );

  if (hydrateData.ok) {
    const { data } = hydrateData;
    yield put(hydrateSuccess({ data }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(hydrateError(message));
  }
}

export function* watchHydrateRequest(api) {
  yield takeEvery(HYDRATE_REQUEST, hydrate, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchHydrateRequest, api)]);
}

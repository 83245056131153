export const FUEL_MANAGEMENT_USERS_LIST_REQUEST =
  'FUEL_MANAGEMENT_USERS_LIST_REQUEST';
export const FUEL_MANAGEMENT_USERS_LIST_SUCCESS =
  'FUEL_MANAGEMENT_USERS_LIST_SUCCESS';
export const FUEL_MANAGEMENT_USERS_LIST_ERROR =
  'FUEL_MANAGEMENT_USERS_LIST_ERROR';
export const FUEL_MANAGEMENT_CREATE_USER_REQUEST =
  'FUEL_MANAGEMENT_CREATE_USER_REQUEST';
export const FUEL_MANAGEMENT_CREATE_USER_SUCCESS =
  'FUEL_MANAGEMENT_CREATE_USER_SUCCESS';
export const FUEL_MANAGEMENT_CREATE_USER_ERROR =
  'FUEL_MANAGEMENT_CREATE_USER_ERROR';
export const FUEL_MANAGEMENT_CREATE_USER_RESET =
  'FUEL_MANAGEMENT_CREATE_USER_RESET';
export const FUEL_MANAGEMENT_EDIT_USER_REQUEST =
  'FUEL_MANAGEMENT_EDIT_USER_REQUEST';
export const FUEL_MANAGEMENT_EDIT_USER_SUCCESS =
  'FUEL_MANAGEMENT_EDIT_USER_SUCCESS';
export const FUEL_MANAGEMENT_EDIT_USER_ERROR =
  'FUEL_MANAGEMENT_EDIT_USER_ERROR';
export const FUEL_MANAGEMENT_REMOVE_USER_REQUEST =
  'FUEL_MANAGEMENT_REMOVE_USER_REQUEST';
export const FUEL_MANAGEMENT_REMOVE_USER_SUCCESS =
  'FUEL_MANAGEMENT_REMOVE_USER_SUCCESS';
export const FUEL_MANAGEMENT_REMOVE_USER_ERROR =
  'FUEL_MANAGEMENT_REMOVE_USER_ERROR';
export const FUEL_MANAGEMENT_RECOVER_USER_REQUEST =
  'FUEL_MANAGEMENT_RECOVER_USER_REQUEST';
export const FUEL_MANAGEMENT_RECOVER_USER_SUCCESS =
  'FUEL_MANAGEMENT_RECOVER_USER_SUCCESS';
export const FUEL_MANAGEMENT_RECOVER_USER_ERROR =
  'FUEL_MANAGEMENT_RECOVER_USER_ERROR';

export const listFuelManagementUsers = params => ({
  type: FUEL_MANAGEMENT_USERS_LIST_REQUEST,
  payload: { params },
});

export const listFuelManagementUsersSuccess = payload => ({
  type: FUEL_MANAGEMENT_USERS_LIST_SUCCESS,
  payload,
});

export const listFuelManagementUsersError = message => ({
  type: FUEL_MANAGEMENT_USERS_LIST_ERROR,
  payload: { message },
});

export const createUser = (data, params) => ({
  type: FUEL_MANAGEMENT_CREATE_USER_REQUEST,
  payload: { data, params },
});

export const createUserSuccess = payload => ({
  type: FUEL_MANAGEMENT_CREATE_USER_SUCCESS,
  payload,
});

export const createUserError = message => ({
  type: FUEL_MANAGEMENT_CREATE_USER_ERROR,
  payload: { message },
});

export const createUserReset = () => ({
  type: FUEL_MANAGEMENT_CREATE_USER_RESET,
  payload: {},
});

export const editUser = (id, data, params) => ({
  type: FUEL_MANAGEMENT_EDIT_USER_REQUEST,
  payload: { id, data, params },
});

export const editUserSuccess = payload => ({
  type: FUEL_MANAGEMENT_EDIT_USER_SUCCESS,
  payload,
});

export const editUserError = message => ({
  type: FUEL_MANAGEMENT_EDIT_USER_ERROR,
  payload: { message },
});

export const removeUser = (id, params) => ({
  type: FUEL_MANAGEMENT_REMOVE_USER_REQUEST,
  payload: { id, params },
});

export const removeUserSuccess = payload => ({
  type: FUEL_MANAGEMENT_REMOVE_USER_SUCCESS,
  payload,
});

export const removeUserError = message => ({
  type: FUEL_MANAGEMENT_REMOVE_USER_ERROR,
  payload: { message },
});

export const recoverUser = (id, params) => ({
  type: FUEL_MANAGEMENT_RECOVER_USER_REQUEST,
  payload: { id, params },
});

export const recoverUserSuccess = payload => ({
  type: FUEL_MANAGEMENT_RECOVER_USER_SUCCESS,
  payload,
});

export const recoverUserError = message => ({
  type: FUEL_MANAGEMENT_RECOVER_USER_ERROR,
  payload: { message },
});

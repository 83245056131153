import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/admin/invoice${objectToURLParams(parameters)}`);

export const getInvoiceTransactions = invoiceId =>
  api.get(`/admin/invoice/${invoiceId}/transaction`);

export const getTransactions = parameters =>
  api.get(`/admin/transaction${objectToURLParams(parameters)}`);

export const reprocessBillings = payload => api.post('/admin/invoice', payload);

export const updateStatus = (id, data) => api.put(`/admin/invoice/${id}`, data);

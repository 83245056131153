import { all, select, put, call, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import { USER_LIST_REQUEST } from '../actions';

import { listUserSuccess, listUserError } from './actions';

import { getCurrentOrganization } from '../organizations/selectors';

function* listUsers(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const users = yield call(
    api.users.list,
    APIParams({
      ...payload.params,
      gasStationId: currentOrganization.id,
    })
  );

  if (users.ok) {
    const list = users.data.docs;
    const { current: currentPage, total: totalPages } = users.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = users.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listUserSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listUserError(message));
  }
}

export function* watchListTransactions(api) {
  yield takeEvery(USER_LIST_REQUEST, listUsers, api);
}

export default function* rootSaga() {
  yield all([]);
}

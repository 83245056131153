import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  PLANS_LIST_REQUEST,
  PLAN_CREATION_REQUEST,
  PLAN_DELETE_REQUEST,
  PLAN_EDIT_REQUEST,
  listPlans,
  planRequestError,
  planRequestSuccess,
} from './actions';

function* getPlans(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      planRequestSuccess({
        list,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar planos';

    yield put(planRequestError(message));
  }
}

function* createPlan(api, { payload }) {
  const { data, params } = payload;
  const response = yield call(api.create, data);

  if (response.ok) {
    yield put(listPlans(params));
  } else {
    const message = 'Não foi possível criar este plano';

    yield put(planRequestError(message));
  }
}

function* editPlan(api, { payload }) {
  const { id, data, params } = payload;
  const response = yield call(api.edit, id, data);

  if (response.ok) {
    yield put(listPlans(params));
  } else {
    const message = 'Não foi possível editar este plano';
    yield put(planRequestError(message));
  }
}

function* deletePlan(api, { payload }) {
  const { id, params } = payload;
  const response = yield call(api.remove, id);

  if (response.ok) {
    yield put(listPlans(params));
  } else {
    const message = 'Não foi possível deletar este plano';
    yield put(planRequestError(message));
  }
}

function* watchListPlans(api) {
  yield takeEvery(PLANS_LIST_REQUEST, getPlans, api);
}

export function* watchCreatePlan(api) {
  yield takeEvery(PLAN_CREATION_REQUEST, createPlan, api);
}

export function* watchEditPlan(api) {
  yield takeEvery(PLAN_EDIT_REQUEST, editPlan, api);
}

export function* watchDeletePlan(api) {
  yield takeEvery(PLAN_DELETE_REQUEST, deletePlan, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListPlans, api),
    fork(watchCreatePlan, api),
    fork(watchEditPlan, api),
    fork(watchDeletePlan, api),
  ]);
}

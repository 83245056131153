import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  listFuelManagementUsers as listFuelManagementUsersAction,
  listFuelManagementUsersSuccess,
  listFuelManagementUsersError,
  FUEL_MANAGEMENT_USERS_LIST_REQUEST,
  FUEL_MANAGEMENT_CREATE_USER_REQUEST,
  createUserSuccess,
  createUserError,
  FUEL_MANAGEMENT_EDIT_USER_REQUEST,
  editUserSuccess,
  editUserError,
  FUEL_MANAGEMENT_REMOVE_USER_REQUEST,
  removeUserSuccess,
  removeUserError,
  FUEL_MANAGEMENT_RECOVER_USER_REQUEST,
  recoverUserSuccess,
  recoverUserError,
} from './actions';

function* listFuelManagementUsers(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(listFuelManagementUsersSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(listFuelManagementUsersError(message));
  }
}

function* createUser(api, { payload }) {
  const response = yield call(api.create, payload.data);

  if (response.ok) {
    yield put(createUserSuccess(response.data));
    yield put(listFuelManagementUsersAction(payload.params));
  } else {
    let message = '';
    if (response.status === 400) {
      message = 'Este usuário já existe';
    } else {
      message = 'Não foi possível criar este usuário';
    }

    yield put(createUserError(message));
  }
}

function* editUser(api, { payload }) {
  const response = yield call(api.update, payload.id, payload.data);

  if (response.ok) {
    yield put(editUserSuccess(response.data));
    yield put(listFuelManagementUsersAction(payload.params));
  } else {
    let message = 'Não foi possível editar este usuário';

    yield put(editUserError(message));
  }
}

function* removeUser(api, { payload }) {
  const response = yield call(api.remove, payload.id);

  if (response.ok) {
    yield put(removeUserSuccess(response.data));
    yield put(listFuelManagementUsersAction(payload.params));
  } else {
    let message = 'Não foi possível remover este usuário';

    yield put(removeUserError(message));
  }
}

function* recoverUser(api, { payload }) {
  const response = yield call(api.recover, payload.id);

  if (response.ok) {
    yield put(recoverUserSuccess(response.data));
    yield put(listFuelManagementUsersAction(payload.params));
  } else {
    let message = 'Não foi possível recuperar este usuário';

    yield put(recoverUserError(message));
  }
}

export function* watchListFuelManagementUsers(api) {
  yield takeEvery(
    FUEL_MANAGEMENT_USERS_LIST_REQUEST,
    listFuelManagementUsers,
    api
  );
}

export function* watchCreateUser(api) {
  yield takeEvery(FUEL_MANAGEMENT_CREATE_USER_REQUEST, createUser, api);
}

export function* watchEditUser(api) {
  yield takeEvery(FUEL_MANAGEMENT_EDIT_USER_REQUEST, editUser, api);
}

export function* watchRemoveUser(api) {
  yield takeEvery(FUEL_MANAGEMENT_REMOVE_USER_REQUEST, removeUser, api);
}

export function* watchRecoverUser(api) {
  yield takeEvery(FUEL_MANAGEMENT_RECOVER_USER_REQUEST, recoverUser, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListFuelManagementUsers, api),
    fork(watchCreateUser, api),
    fork(watchEditUser, api),
    fork(watchRemoveUser, api),
    fork(watchRecoverUser, api),
  ]);
}

import { objectToURLParams } from 'helpers/Utils';
import api from './api';

export const list = parameters =>
  api.get(`/menu${objectToURLParams(parameters)}`);

export const create = data => api.post('/admin/menu', data);

export const edit = (id, data) => api.put(`/menu/${id}`, data);

export const remove = id => api.delete(`/menu/${id}`);

export const getModule = parameters =>
  api.get(`/module${objectToURLParams(parameters)}`);

import api from './api';

export const login = data => api.post('login', data);

export const setHeader = (name, value) => api.setHeader(name, value);

export const forgetPassword = email => api.post('login/forgot', { email });

export const changePassword = ({ password, newPassword }) =>
  api.put('user/authenticationPass', { password, newPassword });

export const validateSession = () => api.get('user/validSession');

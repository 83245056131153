import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import * as deposits from './actions';

function* listDeposits(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(deposits.onListDepositsSuccess({ list, pages, items }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(deposits.onListDepositsError(message));
  }
}

function* listDepositsAttachments(api, { payload }) {
  const response = yield call(
    api.listAttachments,
    APIParams({ ...payload.params })
  );

  if (response.ok) {
    const list = response.data.docs;

    yield put(deposits.onDepositAttachmentSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';

    yield put(deposits.onDepositAttachmentError(message));
  }
}

function* createDepositsAttachments(api, { payload }) {
  const response = yield call(api.postAttachment, payload.params);

  if (response.ok) {
    yield put(
      deposits.listDepositAttachments({
        _deposit: payload.params.get('_deposit'),
      })
    );
  } else {
    const message = 'Ocorreu um erro ao adicionar o arquivo';

    yield put(deposits.onDepositAttachmentError(message));
  }
}

function* removeDepositsAttachment(api, { payload }) {
  const response = yield call(api.removeAttachment, payload.id);

  if (response.ok) {
    yield put(
      deposits.listDepositAttachments({
        _deposit: payload.deposit,
      })
    );
  } else {
    const message = 'Ocorreu um erro ao remover o arquivo';

    yield put(deposits.onDepositAttachmentError(message));
  }
}

function* cancelDeposit(api, { payload }) {
  const response = yield call(api.cancelDeposit, payload.id, payload.data);

  if (response.ok) {
    yield put(deposits.onCancelDepositSuccess());
  } else {
    const message = 'Ocorreu um erro ao cancelar o depósito';

    yield put(deposits.onCancelDepositError(message));
  }
}

function* watchListDeposits(api) {
  yield takeEvery(deposits.DEPOSIT_LIST_REQUEST, listDeposits, api);
}

function* watchListDepositAttachments(api) {
  yield takeEvery(
    deposits.DEPOSIT_ATTACHMENTS_REQUEST,
    listDepositsAttachments,
    api
  );
}

function* watchCreateDepositsAttachments(api) {
  yield takeEvery(
    deposits.CREATE_DEPOSIT_ATTACHMENT,
    createDepositsAttachments,
    api
  );
}

export function* watchRemoveDepositsAttachment(api) {
  yield takeEvery(
    deposits.DELETE_DEPOSIT_ATTACHMENT,
    removeDepositsAttachment,
    api
  );
}

export function* watchCancelDeposit(api) {
  yield takeEvery(deposits.CANCEL_DEPOSIT_REQUEST, cancelDeposit, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchListDeposits, api),
    fork(watchListDepositAttachments, api),
    fork(watchCreateDepositsAttachments, api),
    fork(watchRemoveDepositsAttachment, api),
    fork(watchCancelDeposit, api),
  ]);
}

export const GAS_STATION_BILLINGS_LIST_REQUEST =
  'GAS_STATION_BILLINGS_LIST_REQUEST';
export const GAS_STATION_BILLINGS_LIST_SUCCESS =
  'GAS_STATION_BILLINGS_LIST_SUCCESS';
export const GAS_STATION_BILLINGS_LIST_ERROR =
  'GAS_STATION_BILLINGS_LIST_ERROR';
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';
export const GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST =
  'GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST';
export const GAS_STATION_BILLINGS_UPDATE_STATUS_SUCCESS =
  'GAS_STATION_BILLINGS_UPDATE_STATUS_SUCCESS';
export const GAS_STATION_BILLINGS_UPDATE_STATUS_ERROR =
  'GAS_STATION_BILLINGS_UPDATE_STATUS_ERROR';
export const GAS_STATION_BILLINGS_REPROCESS_REQUEST =
  'GAS_STATION_BILLINGS_REPROCESS_REQUEST';
export const GAS_STATION_BILLINGS_REPROCESS_SUCCESS =
  'GAS_STATION_BILLINGS_REPROCESS_SUCCESS';
export const GAS_STATION_BILLINGS_REPROCESS_ERROR =
  'GAS_STATION_BILLINGS_REPROCESS_ERROR';
export const SCHEDULE_GAS_STATION_BILLINGS_REQUEST =
  'SCHEDULE_GAS_STATION_BILLINGS_REQUEST';
export const SCHEDULE_GAS_STATION_BILLINGS_SUCCESS =
  'SCHEDULE_GAS_STATION_BILLINGS_SUCCESS';
export const SCHEDULE_GAS_STATION_BILLINGS_ERROR =
  'SCHEDULE_GAS_STATION_BILLINGS_ERROR';
export const SCHEDULE_GAS_STATION_BILLINGS_RESET =
  'SCHEDULE_GAS_STATION_BILLINGS_RESET';
export const GET_PAYMENT_HISTORY_REQUEST = 'GET_PAYMENT_HISTORY_REQUEST';
export const GET_PAYMENT_HISTORY_SUCCESS = 'GET_PAYMENT_HISTORY_SUCCESS';
export const GET_PAYMENT_HISTORY_ERROR = 'GET_PAYMENT_HISTORY_ERROR';

export const listGasStationBillings = params => ({
  type: GAS_STATION_BILLINGS_LIST_REQUEST,
  payload: { params },
});

export const listGasStationBillingsSuccess = payload => ({
  type: GAS_STATION_BILLINGS_LIST_SUCCESS,
  payload,
});

export const listGasStationBillingsError = message => ({
  type: GAS_STATION_BILLINGS_LIST_ERROR,
  payload: { message },
});

export const getTransactions = params => ({
  type: GET_TRANSACTIONS_REQUEST,
  payload: { params },
});

export const getTransactionsSuccess = payload => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload,
});

export const getTransactionsError = message => ({
  type: GET_TRANSACTIONS_ERROR,
  payload: { message },
});

export const updateStatus = (id, data, params) => ({
  type: GAS_STATION_BILLINGS_UPDATE_STATUS_REQUEST,
  payload: { id, data, params },
});

export const updateStatusSuccess = payload => ({
  type: GAS_STATION_BILLINGS_UPDATE_STATUS_SUCCESS,
  payload,
});

export const updateStatusError = message => ({
  type: GAS_STATION_BILLINGS_UPDATE_STATUS_ERROR,
  payload: { message },
});

export const reprocessBillings = (data, params) => ({
  type: GAS_STATION_BILLINGS_REPROCESS_REQUEST,
  payload: { data, params },
});

export const reprocessBillingsSuccess = () => ({
  type: GAS_STATION_BILLINGS_REPROCESS_SUCCESS,
});

export const reprocessBillingsError = message => ({
  type: GAS_STATION_BILLINGS_REPROCESS_ERROR,
  payload: { message },
});

export const scheduleGasStationBillings = (id, params) => ({
  type: SCHEDULE_GAS_STATION_BILLINGS_REQUEST,
  payload: { id, params },
});

export const scheduleGasStationBillingsSuccess = () => ({
  type: SCHEDULE_GAS_STATION_BILLINGS_SUCCESS,
});

export const scheduleGasStationBillingsError = message => ({
  type: SCHEDULE_GAS_STATION_BILLINGS_ERROR,
  payload: { message },
});

export const scheduleGasStationBillingsReset = () => ({
  type: SCHEDULE_GAS_STATION_BILLINGS_RESET,
});

export const getPaymentHistory = params => ({
  type: GET_PAYMENT_HISTORY_REQUEST,
  payload: { params },
});

export const getPaymentHistorySuccess = payload => ({
  type: GET_PAYMENT_HISTORY_SUCCESS,
  payload,
});

export const getPaymentHistoryError = message => ({
  type: GET_PAYMENT_HISTORY_ERROR,
  payload: { message },
});

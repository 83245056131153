import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as permissions from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const permissionsList = response.data.docs;
    yield put(permissions.onSuccess(permissionsList));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(permissions.onError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, [{ ...payload.permissions }]);

  if (response.ok) {
    yield put(permissions.getPermissions());
  } else {
    const message = 'Não foi possível criar o permissão';
    yield put(permissions.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.permission);

  if (response.ok) {
    yield put(permissions.getPermissions());
  } else {
    const message = 'Não foi possível editar o permissão';
    yield put(permissions.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.permission);

  if (response.ok) {
    yield put(permissions.getPermissions());
  } else {
    const message = 'Não foi possível remover o permissão';
    yield put(permissions.onError(message));
  }
}

function* watchList(api) {
  yield takeEvery(permissions.ACTIONS.GET_PERMISSIONS, list, api);
}

function* watchPost(api) {
  yield takeEvery(permissions.ACTIONS.CREATE_PERMISSION, post, api);
}

function* watchPut(api) {
  yield takeEvery(permissions.ACTIONS.EDIT_PERMISSION, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(permissions.ACTIONS.DELETE_PERMISSION, remove, api);
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchPost, api),
    fork(watchPut, api),
    fork(watchDelete, api),
  ]);
}

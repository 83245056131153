import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';
import {
  REPORTS_LIST_REQUEST,
  listReportsSuccess,
  listReportsError,
} from './actions';

import { getCurrentOrganization } from '../organizations/selectors';

function* listReports(api, { payload }) {
  const currentOrganization = yield select(getCurrentOrganization);

  const reports = yield call(
    api.list,
    APIParams({
      ...payload.params,
      gasStationId: currentOrganization.id,
    })
  );

  if (reports.ok) {
    const list = reports.data;

    yield put(listReportsSuccess({ list }));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(listReportsError(message));
  }
}

function* watchListReports(api) {
  yield takeEvery(REPORTS_LIST_REQUEST, listReports, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListReports, api)]);
}

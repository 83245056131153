import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  SUBSCRIPTIONS_LIST_REQUEST,
  SUBSCRIPTION_CREATION_REQUEST,
  SUBSCRIPTION_EDIT_REQUEST,
  listSubscriptions,
  subscriptionRequestError,
  subscriptionRequestSuccess,
} from './actions';

function* get(api, { payload }) {
  const response = yield call(api.list, payload.organization);

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      subscriptionRequestSuccess({
        list,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar assinaturas';

    yield put(subscriptionRequestError(message));
  }
}

function* post(api, { payload }) {
  const response = yield call(api.post, { ...payload.data });

  if (response.ok) {
    yield put(listSubscriptions(payload.organization));
  } else {
    const message = 'Não foi possível criar assinatura';
    yield put(subscriptionRequestError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.data);

  if (response.ok) {
    yield put(listSubscriptions(payload.organization));
  } else {
    const message = 'Não foi possível editar assinatura';
    yield put(subscriptionRequestError(message));
  }
}

function* watchGet(api) {
  yield takeEvery(SUBSCRIPTIONS_LIST_REQUEST, get, api);
}

function* watchPost(api) {
  yield takeEvery(SUBSCRIPTION_CREATION_REQUEST, post, api);
}

function* watchPut(api) {
  yield takeEvery(SUBSCRIPTION_EDIT_REQUEST, edit, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchGet, api), fork(watchPost, api), fork(watchPut, api)]);
}

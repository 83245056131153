export const BANK_LIST_REQUEST = 'BANK_LIST_REQUEST';
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS';
export const BANK_LIST_ERROR = 'BANK_LIST_ERROR';

export const listBanks = params => ({
  type: BANK_LIST_REQUEST,
  payload: { params },
});

export const onListSuccess = payload => ({
  type: BANK_LIST_SUCCESS,
  payload,
});

export const onListError = message => ({
  type: BANK_LIST_ERROR,
  payload: { message },
});

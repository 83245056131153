export const BILLING_STATEMENTS_ACTIONS = {
  BILLING_STATEMENTS_REQUEST: 'BILLING_STATEMENTS_REQUEST',
  BILLING_STATEMENTS_SUCCESS: 'BILLING_STATEMENTS_SUCCESS',
  BILLING_STATEMENTS_ERROR: 'BILLING_STATEMENTS_ERROR',
};

export const listBillingStatements = payload => ({
  type: BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_REQUEST,
  payload,
});

export const listBillingStatementsSuccess = payload => ({
  type: BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_SUCCESS,
  payload,
});

export const listBillingStatementsError = message => ({
  type: BILLING_STATEMENTS_ACTIONS.BILLING_STATEMENTS_ERROR,
  payload: { message },
});

import produce from 'immer';
import {
  CREATE_PAYMENT_AUTHORIZATION,
  CREATE_PAYMENT_AUTHORIZATION_ERROR,
  CREATE_PAYMENT_AUTHORIZATION_SUCCESS,
  LIST_PAYMENT_AUTHORIZATION,
  LIST_PAYMENT_AUTHORIZATION_ERROR,
  LIST_PAYMENT_AUTHORIZATION_SUCCESS,
  RECOVER_PAYMENT_AUTHORIZATION,
  RECOVER_PAYMENT_AUTHORIZATION_ERROR,
  RECOVER_PAYMENT_AUTHORIZATION_SUCCESS,
  REMOVE_PAYMENT_AUTHORIZATION,
  REMOVE_PAYMENT_AUTHORIZATION_ERROR,
  REMOVE_PAYMENT_AUTHORIZATION_SUCCESS,
  UPDATE_PAYMENT_AUTHORIZATION,
  UPDATE_PAYMENT_AUTHORIZATION_ERROR,
  UPDATE_PAYMENT_AUTHORIZATION_SUCCESS,
} from './actions';

const INIT_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  creation: {
    error: '',
    success: false,
  },
  update: {
    error: '',
    success: false,
  },
  remove: {
    error: '',
    success: false,
  },
  recover: {
    error: '',
    success: false,
  },
};

export default (state = INIT_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case LIST_PAYMENT_AUTHORIZATION:
        draft.loading = true;
        draft.error = '';
        draft.creation.error = '';
        draft.creation.success = false;
        draft.update.error = '';
        draft.update.success = false;
        draft.remove.error = '';
        draft.remove.success = false;
        draft.recover.error = '';
        draft.recover.success = false;
        break;

      case LIST_PAYMENT_AUTHORIZATION_SUCCESS:
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.success = true;
        draft.error = '';
        break;

      case LIST_PAYMENT_AUTHORIZATION_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        draft.success = false;
        break;

      case CREATE_PAYMENT_AUTHORIZATION:
        draft.loading = true;
        draft.creation.error = '';
        break;

      case CREATE_PAYMENT_AUTHORIZATION_SUCCESS:
        draft.loading = false;
        draft.creation.error = '';
        draft.creation.success = true;
        break;

      case CREATE_PAYMENT_AUTHORIZATION_ERROR:
        draft.loading = false;
        draft.list = [];
        draft.creation.error = action.payload.message;
        draft.creation.success = false;
        break;

      case UPDATE_PAYMENT_AUTHORIZATION:
        draft.loading = true;
        draft.update.error = '';
        break;

      case UPDATE_PAYMENT_AUTHORIZATION_SUCCESS:
        draft.loading = false;
        draft.update.error = '';
        draft.update.success = true;
        break;

      case UPDATE_PAYMENT_AUTHORIZATION_ERROR:
        draft.loading = false;
        draft.update.error = action.payload.message;
        draft.update.success = false;
        break;

      case REMOVE_PAYMENT_AUTHORIZATION:
        draft.loading = true;
        draft.remove.error = '';
        break;

      case REMOVE_PAYMENT_AUTHORIZATION_SUCCESS:
        draft.loading = false;
        draft.remove.error = '';
        draft.remove.success = true;
        break;

      case REMOVE_PAYMENT_AUTHORIZATION_ERROR:
        draft.loading = false;
        draft.remove.error = action.payload.message;
        draft.remove.success = false;
        break;

      case RECOVER_PAYMENT_AUTHORIZATION:
        draft.loading = true;
        draft.recover.error = '';
        break;

      case RECOVER_PAYMENT_AUTHORIZATION_SUCCESS:
        draft.loading = false;
        draft.recover.error = '';
        draft.recover.success = true;
        break;

      case RECOVER_PAYMENT_AUTHORIZATION_ERROR:
        draft.loading = false;
        draft.recover.error = action.payload.message;
        draft.recover.success = false;
        break;

      default:
        return state;
    }
  });
};

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { APIParams } from 'helpers/Utils';

import {
  PURCHASE_LIST_REQUEST,
  PURCHASE_REPROCESS_REQUEST,
  reprocessPurchaseSuccess,
  reprocessPurchaseError,
  listPurchase as listPurchaseAction,
  listPurchaseSuccess,
  listPurchaseError,
} from './actions';

function* listPurchase(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    const list = response.data.docs;
    const { current: currentPage, total: totalPages } = response.data.pages;
    const { total: totalItems, skip: skipItems = 0 } = response.data.items;
    const pages = { currentPage: Number(currentPage), totalPages };
    const items = { totalItems, skipItems };

    yield put(
      listPurchaseSuccess({
        list,
        pages,
        items,
      })
    );
  } else {
    const message = 'Não foi possível carregar a lista de compras';

    yield put(listPurchaseError(message));
  }
}

function* reprocessPurchase(api, { payload }) {
  const response = yield call(api.reprocess);

  if (response.ok) {
    yield put(reprocessPurchaseSuccess());
    yield put(listPurchaseAction(payload.params));
  } else {
    const message = 'Não foi possível reprocessar a compra';

    yield put(reprocessPurchaseError(message));
  }
}

export function* watchListPurchase(api) {
  yield takeEvery(PURCHASE_LIST_REQUEST, listPurchase, api);
}

export function* watchRepoPurchase(api) {
  yield takeEvery(PURCHASE_REPROCESS_REQUEST, reprocessPurchase, api);
}

export default function* rootSaga(api) {
  yield all([fork(watchListPurchase, api), fork(watchRepoPurchase, api)]);
}

export const MENU_LIST_REQUEST = 'MENU_LIST_REQUEST';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_LIST_ERROR = 'MENU_LIST_ERROR';
export const CREATE_MENU = 'CREATE_MENU';
export const CREATE_MENU_SUCCESS = 'CREATE_MENU_SUCCESS';
export const CREATE_MENU_ERROR = 'CREATE_MENU_ERROR';
export const EDIT_MENU = 'EDIT_MENU';
export const EDIT_MENU_SUCCESS = 'EDIT_MENU_SUCCESS';
export const EDIT_MENU_ERROR = 'EDIT_MENU_ERROR';
export const DELETE_MENU = 'DELETE_MENU';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_ERROR = 'DELETE_MENU_ERROR';
export const UNDELETE_MENU = 'UNDELETE_MENU';
export const UNDELETE_MENU_ERROR = 'UNDELETE_MENU_ERROR';

export const getMenu = params => ({
  type: MENU_LIST_REQUEST,
  payload: { params },
});

export const listMenuSuccess = payload => ({
  type: MENU_LIST_SUCCESS,
  payload,
});

export const listMenuError = message => ({
  type: MENU_LIST_ERROR,
  payload: { message },
});

export const createMenu = (module, params) => ({
  type: CREATE_MENU,
  payload: { module, params },
});

export const createMenuSuccess = payload => ({
  type: CREATE_MENU_SUCCESS,
  payload,
});

export const createMenuError = message => ({
  type: CREATE_MENU_ERROR,
  payload: { message },
});

export const editMenu = (id, module, params) => ({
  type: EDIT_MENU,
  payload: { id, module, params },
});

export const editMenuSuccess = payload => ({
  type: EDIT_MENU_SUCCESS,
  payload,
});

export const editMenuError = message => ({
  type: EDIT_MENU_ERROR,
  payload: { message },
});

export const deleteMenu = (module, params) => ({
  type: DELETE_MENU,
  payload: { module, params },
});

export const deleteMenuSuccess = payload => ({
  type: DELETE_MENU_SUCCESS,
  payload,
});

export const deleteMenuError = message => ({
  type: DELETE_MENU_ERROR,
  payload: { message },
});

export const undeleteMenuRequest = (id, params) => ({
  type: UNDELETE_MENU,
  payload: { id, params },
});

export const undeleteMenuError = message => ({
  type: UNDELETE_MENU_ERROR,
  payload: { message },
});

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_ERROR = 'USER_LIST_ERROR';

export const listUser = params => ({
  type: USER_LIST_REQUEST,
  payload: { params },
});

export const listUserSuccess = payload => ({
  type: USER_LIST_SUCCESS,
  payload,
});

export const listUserError = message => ({
  type: USER_LIST_ERROR,
  payload: { message },
});

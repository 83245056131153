import produce from 'immer';

import {
  FUEL_MANAGEMENT_USERS_LIST_REQUEST,
  FUEL_MANAGEMENT_USERS_LIST_SUCCESS,
  FUEL_MANAGEMENT_USERS_LIST_ERROR,
  FUEL_MANAGEMENT_CREATE_USER_REQUEST,
  FUEL_MANAGEMENT_CREATE_USER_SUCCESS,
  FUEL_MANAGEMENT_CREATE_USER_ERROR,
  FUEL_MANAGEMENT_CREATE_USER_RESET,
  FUEL_MANAGEMENT_EDIT_USER_REQUEST,
  FUEL_MANAGEMENT_EDIT_USER_SUCCESS,
  FUEL_MANAGEMENT_EDIT_USER_ERROR,
  FUEL_MANAGEMENT_REMOVE_USER_REQUEST,
  FUEL_MANAGEMENT_REMOVE_USER_SUCCESS,
  FUEL_MANAGEMENT_REMOVE_USER_ERROR,
  FUEL_MANAGEMENT_RECOVER_USER_REQUEST,
  FUEL_MANAGEMENT_RECOVER_USER_SUCCESS,
  FUEL_MANAGEMENT_RECOVER_USER_ERROR,
} from './actions';

const INITIAL_STATE = {
  list: [],
  currentPage: '',
  totalPages: '',
  totalItems: '',
  skipItems: 0,
  loading: false,
  error: '',
  success: false,
  createUser: {
    loading: false,
    error: '',
    success: false,
  },
  editUser: {
    loading: false,
    error: '',
    success: false,
  },
  errorRemove: {
    loading: false,
    error: '',
    success: false,
  },
  recoverUser: {
    loading: false,
    error: '',
    success: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FUEL_MANAGEMENT_USERS_LIST_REQUEST: {
        draft.loading = true;
        draft.error = '';
        break;
      }

      case FUEL_MANAGEMENT_USERS_LIST_SUCCESS: {
        draft.loading = false;
        draft.list = action.payload.list;
        draft.currentPage = action.payload.pages.currentPage;
        draft.totalPages = action.payload.pages.totalPages;
        draft.totalItems = action.payload.items.totalItems;
        draft.skipItems = action.payload.items.skipItems;
        draft.error = '';
        break;
      }

      case FUEL_MANAGEMENT_USERS_LIST_ERROR: {
        draft.loading = false;
        draft.list = [];
        draft.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_CREATE_USER_REQUEST: {
        draft.createUser.loading = true;
        draft.createUser.error = '';
        draft.createUser.success = false;
        break;
      }

      case FUEL_MANAGEMENT_CREATE_USER_SUCCESS: {
        draft.createUser.loading = false;
        draft.createUser.success = true;
        draft.createUser.error = '';
        break;
      }

      case FUEL_MANAGEMENT_CREATE_USER_ERROR: {
        draft.createUser.loading = false;
        draft.createUser.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_CREATE_USER_RESET: {
        draft.createUser.loading = false;
        draft.createUser.error = '';
        draft.createUser.success = false;
        break;
      }

      case FUEL_MANAGEMENT_EDIT_USER_REQUEST: {
        draft.editUser.loading = true;
        draft.editUser.error = '';
        draft.editUser.success = false;
        break;
      }

      case FUEL_MANAGEMENT_EDIT_USER_SUCCESS: {
        draft.editUser.loading = false;
        draft.editUser.success = true;
        draft.editUser.error = '';
        break;
      }

      case FUEL_MANAGEMENT_EDIT_USER_ERROR: {
        draft.editUser.loading = false;
        draft.editUser.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_REMOVE_USER_REQUEST: {
        draft.errorRemove.loading = true;
        draft.errorRemove.error = '';
        draft.errorRemove.success = false;
        break;
      }

      case FUEL_MANAGEMENT_REMOVE_USER_SUCCESS: {
        draft.errorRemove.loading = false;
        draft.errorRemove.success = true;
        draft.errorRemove.error = '';
        break;
      }

      case FUEL_MANAGEMENT_REMOVE_USER_ERROR: {
        draft.errorRemove.loading = false;
        draft.errorRemove.error = action.payload.message;
        break;
      }

      case FUEL_MANAGEMENT_RECOVER_USER_REQUEST: {
        draft.recoverUser.loading = true;
        draft.recoverUser.error = '';
        draft.recoverUser.success = false;
        break;
      }

      case FUEL_MANAGEMENT_RECOVER_USER_SUCCESS: {
        draft.recoverUser.loading = false;
        draft.recoverUser.success = true;
        draft.recoverUser.error = '';
        break;
      }

      case FUEL_MANAGEMENT_RECOVER_USER_ERROR: {
        draft.recoverUser.loading = false;
        draft.recoverUser.error = action.payload.message;
        break;
      }

      default:
        return state;
    }
  });
};

import { APIParams } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import * as discounts from './actions';

function* list(api, { payload }) {
  const response = yield call(api.list, APIParams({ ...payload.params }));

  if (response.ok) {
    yield put(discounts.onSuccess(response.data.docs));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(discounts.onError(message));
  }
}

function* create(api, { payload }) {
  const response = yield call(api.post, payload.discount);

  if (response.ok) {
    yield put(discounts.getDiscounts(payload.params));
  } else {
    const message = 'Não foi possível criar o desconto';
    yield put(discounts.onError(message));
  }
}

function* edit(api, { payload }) {
  const response = yield call(api.put, payload.id, payload.discount);

  if (response.ok) {
    yield put(discounts.getDiscounts(payload.params));
  } else {
    const message = 'Não foi possível editar o desconto';
    yield put(discounts.onError(message));
  }
}

function* remove(api, { payload }) {
  const response = yield call(api.remove, payload.discount);

  if (response.ok) {
    yield put(discounts.getDiscounts(payload.params));
  } else {
    const message = 'Não foi possível remover o desconto';
    yield put(discounts.onError(message));
  }
}

function* getById(api, { payload }) {
  const response = yield call(api.getById, payload.discount);

  if (response.ok) {
    yield put(discounts.getDiscountByIdSuccess(response.data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(discounts.onError(message));
  }
}

function* getTransactionDiscount(api, { payload }) {
  const response = yield call(
    api.getTransactionDiscount,
    payload.stationId,
    payload.orgId
  );

  if (response.ok) {
    yield put(discounts.getTransactionDiscountSuccess(response.data));
  } else {
    const message = 'Não foi possível carregar estes dados';
    yield put(discounts.getTransactionDiscountError(message));
  }
}

function* watchList(api) {
  yield takeEvery(discounts.ACTIONS.GET_DISCOUNTS, list, api);
}

function* watchCreate(api) {
  yield takeEvery(discounts.ACTIONS.CREATE_DISCOUNT, create, api);
}

function* watchPut(api) {
  yield takeEvery(discounts.ACTIONS.EDIT_DISCOUNT, edit, api);
}

function* watchDelete(api) {
  yield takeEvery(discounts.ACTIONS.DELETE_DISCOUNT, remove, api);
}

function* watchGetById(api) {
  yield takeEvery(discounts.ACTIONS.GET_DISCOUNT_BY_ID, getById, api);
}

function* watchTransactionDiscount(api) {
  yield takeEvery(
    discounts.ACTIONS.GET_TRANSACTION_DISCOUNT,
    getTransactionDiscount,
    api
  );
}

export default function* rootSaga(api) {
  yield all([
    fork(watchList, api),
    fork(watchCreate, api),
    fork(watchPut, api),
    fork(watchDelete, api),
    fork(watchGetById, api),
    fork(watchTransactionDiscount, api),
  ]);
}

export const ACTIONS = {
  GET_USERS: 'GET_USERS',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS: 'PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_ERROR: 'PERMISSION_ERROR',
  UPDATE_USER_PERMISSION: 'UPDATE_USER_PERMISSION',
  UPDATE_USER_PERMISSION_SUCCESS: 'UPDATE_USER_PERMISSION_SUCCESS',
  UPDATE_USER_PERMISSION_ERROR: 'UPDATE_USER_PERMISSION_ERROR',
  CREATE_USER_PERMISSION: 'CREATE_USER_PERMISSION',
  CREATE_USER_PERMISSION_SUCCESS: 'CREATE_USER_PERMISSION_SUCCESS',
  CREATE_USER_PERMISSION_ERROR: 'CREATE_USER_PERMISSION_ERROR',
  RESET_USER_PERMISSIONS_LIST: 'RESET_USER_PERMISSIONS_LIST',
  GET_USER_PERMISSION: 'GET_USER_PERMISSION',
  GET_USER_PERMISSION_SUCCESS: 'GET_USER_PERMISSION_SUCCESS',
  GET_USER_PERMISSION_ERROR: 'GET_USER_PERMISSION_ERROR',
};

export const getUsers = params => ({
  type: ACTIONS.GET_USERS,
  payload: { params },
});

export const getUsersSuccess = users => ({
  type: ACTIONS.GET_USERS_SUCCESS,
  payload: { users },
});

export const getUsersError = message => ({
  type: ACTIONS.GET_USERS_ERROR,
  payload: { message },
});

export const getPermissions = params => ({
  type: ACTIONS.GET_PERMISSIONS,
  payload: { params },
});

export const getPermissionsSuccess = ({ permissions, user }) => ({
  type: ACTIONS.GET_PERMISSIONS_SUCCESS,
  payload: { permissions, user },
});

export const getPermissionsError = message => ({
  type: ACTIONS.GET_PERMISSIONS_ERROR,
  payload: { message },
});

export const updateUserPermissions = (id, permissionId, status) => ({
  type: ACTIONS.UPDATE_USER_PERMISSION,
  payload: { id, permissionId, status },
});

export const updateUserPermissionsSuccess = permissions => ({
  type: ACTIONS.UPDATE_USER_PERMISSION_SUCCESS,
  payload: { permissions },
});

export const updateUserPermissionsError = error => ({
  type: ACTIONS.CREATE_USER_PERMISSION_ERROR,
  payload: { error },
});

export const createUserPermissions = (permissionId, userId, status) => ({
  type: ACTIONS.CREATE_USER_PERMISSION,
  payload: { permissionId, userId, status },
});

export const createUserPermissionsSuccess = permissions => ({
  type: ACTIONS.CREATE_USER_PERMISSION_SUCCESS,
  payload: { permissions },
});

export const createUserPermissionsError = error => ({
  type: ACTIONS.CREATE_USER_PERMISSION_ERROR,
  payload: { error },
});

export const resetUserPermissionsList = () => ({
  type: ACTIONS.RESET_USER_PERMISSIONS_LIST,
});

export const getUserPermissions = userId => ({
  type: ACTIONS.GET_USER_PERMISSION,
  payload: { userId },
});

export const getUserPermissionSuccess = payload => ({
  type: ACTIONS.GET_USER_PERMISSION_SUCCESS,
  payload,
});

export const getUserPermissionError = message => ({
  type: ACTIONS.GET_USER_PERMISSION_ERROR,
  payload: { message },
});
